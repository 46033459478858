// @ flow

import React from 'react';
import { Route } from 'react-router-dom';
import { PASSWORD_PAGE } from '../../routes';
import PasswordManagement from './PasswordManagement';


export default function PasswordRoutes() {
  return (
    <div>
      <Route exact path={PASSWORD_PAGE.INIT} component={PasswordManagement} />
    </div>
  );
}
