// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';

export default function ProtelisPros() {
  return (
    <>
      <div className="container protelis-pros">
        <div className="container-pros">
          <div className="bonus">{I18n.t('home.separator.pros')}</div>
          <div className="inline">{I18n.t('home.separator.protelisPros')}</div>
        </div>

        <div>
          <div className="wrapped-icon wrapped-icon--savings">
            <i className="icon-s icon--defisc" />
          </div>
          <div className="title">
            {I18n.t('home.separator.makeDefiscSavings')}
          </div>
        </div>

        <div>
          <div className="wrapped-icon wrapped-icon--savings">
            <i className="icon-s icon--liss-incomes" />
          </div>
          <div className="title">
            {I18n.t('home.separator.lissIncomes')}
          </div>
        </div>

        <div>
          <div className="wrapped-icon wrapped-icon--savings">
            <i className="icon-s icon--online-savings" />
          </div>
          <div className="title">
            {I18n.t('home.separator.manageSavingsOnline')}
          </div>
        </div>
      </div>
    </>
  )
}
