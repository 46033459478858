// @flow

import React from 'react';
import { REQUIRED_FIELD } from '../../const';

type Props = {
  input: {
    value: string,
    onChange: Function,
  },
  label?: string,
  type: string,
  placeholder?: string,
  meta: Object,
  customError: boolean,
  struct: ?string,
}

export default function InputStruct({label, type, input, placeholder, meta, customError, struct}: Props) {
  return (
    <div className="container-input-pro">
      {
        label &&
        <div className="label">{label}</div>
      }
      <div className={`input-struct ${struct ? 'alinea' : ''}`}>
      <input
        type={type}
        {...input}
        placeholder={placeholder || ''}
        className={`input-pro ${(meta.touched && meta.error || customError) ? 'input-pro--error' : ''}`}
      />
        {
          struct &&
          <span className="struct">{struct}</span>
        }
      </div>
      {
        meta.error && meta.touched && meta.error === REQUIRED_FIELD &&
        <div className="error-form error-form--field">{meta.error}</div>
      }
    </div>
  )
}
