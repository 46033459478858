// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';

type Props = {
    isTokenValid: boolean,
    dispatch: Function,
    newEmailAddress: string
}

class EmailUpdateConfirmation extends React.Component<Props, State> {

    render() {
        const {isTokenValid, newEmailAddress} = this.props;
        return (
            <>
                <div className="user-data-page">
                    <header className="background-user-data">
                        <div className={`blur blur--plain`}>
                            <div className="logo-protelis logo-protelis--baseline" />
                        </div>
                        <div className="user-data-container">
                            <div className="header">
                                <div className="title">
                                    {I18n.t('email.title')}
                                </div>
                            </div>
                            <div className="text-content">
                                {
                                    isTokenValid &&
                                      <>
                                          <div className="text">{I18n.t('email.subtitle')}</div>
                                          <br/>
                                          <div className="text">{I18n.t('email.confirmationMessageBegin')}</div>
                                          <div className="text text--bold">{newEmailAddress}</div>
                                          <div className="text">{I18n.t('email.confirmationMessageEnd')}</div>
                                          <br/>
                                      </>
                                }
                                {
                                    !isTokenValid &&
                                      <>
                                          <div className="text">{I18n.t('email.invalidLink')}</div>
                                      </>
                                }
                            </div>
                        </div>
                    </header>
                </div>
            </>
        );
    }
}

export default EmailUpdateConfirmation;