// @flow

import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { PRODUCTIONS } from '../../../const';

type Props = {
  selectProd: Function,
  toggleInfoProd: Function,
}

export default class Square extends React.Component<Props> {
  selectProd = (production: string) => {
    this.props.toggleInfoProd();
    this.props.selectProd(production);
  }

  render() {
    return (
      <Fragment>
        <div className="title-cat">{I18n.t('home.square.title')}</div>
        <div className="container-square">
          <div className="square square--text">
            <div className="explanation">
              {I18n.t('home.square.selectProd')}
            </div>
          </div>
          {
            Object.keys(PRODUCTIONS).map(productionKey => (
              <div className={`square square--${productionKey}`} key={productionKey}>
                <div className="layer-square">
                  <i className={`icon icon--${productionKey}-white`} />
                  <div>{I18n.t(`home.square.productions.${productionKey}`)}</div>
                  <button
                    className="btn-square"
                    onMouseUp={() => this.selectProd(productionKey)}>{I18n.t('home.square.actions.select')}</button>
                </div>
              </div>
            ))
          }
          <div className="square square--text">
            <div className="contact">
              <i className="icon icon--dial-white" />
              <div>{I18n.t('home.square.question')}</div>
              <a href="mailto:contact@protelis.fr">
                {I18n.t('home.square.contact', { mail: 'contact@protelis.fr' })}
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
