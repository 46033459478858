// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { setResources, updateCoop, updateProd } from '../../../../state/home/homeService';
import { Step1, Step2, Step3, Step4 } from './Steps';
import type { Resources } from '../../../../types/HomeTypes';
import homeApi from '../../../../network/api/HomeApi';
import { checkMailValidity, checkPhoneValidity } from '../../../../utils';
import { REQUIRED_FIELD, TypeStructure } from '../../../../const';

type Props = {
  currentStep: number,
  dispatch: Function,
  handleSubmit: Function,
  setStepForm: Function,
  saveValues: Function,
  values: Object,
  resources: Resources,
}

class ProductionForm extends React.Component<Props> {

  validate = values => {
    const errors = {};
    if (this.props.currentStep === 1 && !values.region) {
      errors.region = 'Sélectionnez votre région pour passer à la suite';
    }
    if (this.props.currentStep === 2 && values.cooperative.length === 0) {
      errors.cooperative = 'Sélectionnez une coopérative pour passer à la suite';
    }
    if (this.props.currentStep === 2 && values.cooperative[0] === '0' && !values.otherCooperative) {
      errors.otherCooperative = REQUIRED_FIELD;
    }
    if (this.props.currentStep === 3 && values.productions.length === 0) {
      errors.productions = 'Sélectionnez au moins une production pour passer à la suite';
    }
    if (this.props.currentStep === 4 && !values.structureType) {
      errors.structureType = REQUIRED_FIELD;
    }
    if (this.props.currentStep === 4 && values.structureType !== TypeStructure.OTHER && values.structureType !== TypeStructure.INDIVIDUAL && !values.structureName) {
      errors.structureName = REQUIRED_FIELD;
    }
    if (this.props.currentStep === 4 && !values.lastName) {
      errors.lastName = REQUIRED_FIELD;
    }
    if (this.props.currentStep === 4 && !values.firstName) {
      errors.firstName = REQUIRED_FIELD
    }
    if (this.props.currentStep === 4 && !values.mail) {
      errors.mail = REQUIRED_FIELD
    }
    if (this.props.currentStep === 4 && !values.phone) {
      errors.phone = REQUIRED_FIELD
    }
    if (this.props.currentStep === 4 && !values.city) {
      errors.city = REQUIRED_FIELD
    }
    if (this.props.currentStep === 4 && !checkMailValidity(values.mail)) {
      errors.global = 'L\'email saisi est invalide';
      errors.mail = 'L\'email saisi est invalide';
      return errors;
    }
    if (this.props.currentStep === 4 && !checkPhoneValidity(values.phone)) {
      errors.global = 'Le numéro de téléphone saisi est invalide';
      errors.phone = 'Le numéro de téléphone saisi est invalide';
      return errors;
    }
    return errors
  };

  updateStep = (type: string, values?: Object): void => {
    if (type === 'minus') {
      if (this.props.currentStep !== 1) {
        this.props.setStepForm(this.props.currentStep - 1)
      }
    } else if (type === 'plus' && values) {
        if (this.props.currentStep === 1) {
          this.props.saveValues({...values, cooperative: []})
          this.props.dispatch(updateCoop(values.region));
        }
        if (this.props.currentStep === 2) {
          this.props.saveValues({...values, productions: []})
          this.props.dispatch(updateProd(values.cooperative[0]));
        }

        if (this.props.currentStep !== 4) {
        this.props.setStepForm(this.props.currentStep + 1)
      }
    }
  };
  onSubmit = (values) => {
    if (this.props.currentStep === 4) {
      this.props.handleSubmit(values)
    }
    else {
      this.props.saveValues(values)
      this.updateStep('plus', values)
    }
  };

  componentDidMount() {
    homeApi.getResources()
      .then(response => response.json())
      .then((resources: Resources) => {
        this.props.dispatch(setResources(resources))
      })
  }

  render() {
    if (!this.props.resources) {
      return false;
    }
    return (
      <div className="container-prod-form">
        <Form
          onSubmit={this.onSubmit}
          initialValues={this.props.values}
          validate={this.validate}
          render={({ handleSubmit, submitting, form, errors, touched, values }) => (
            <form onSubmit={handleSubmit} className="prod-form">
              <div className="content-form">
                <Step1 currentStep={this.props.currentStep} regions={this.props.resources.regions} />
                <Step2 currentStep={this.props.currentStep} cooperatives={this.props.resources.cooperatives} values={values}/>
                <Step3 currentStep={this.props.currentStep} productions={this.props.resources.productions}/>
                <Step4 currentStep={this.props.currentStep} structures={this.props.resources.structures} form={form} errors={errors} touched={touched} values={values} />
              </div>

              <div className="actions">
                {
                  this.props.currentStep !== 1 &&
                  <button
                    type="button"
                    className="btn btn--back"
                    onClick={() => this.updateStep('minus')}
                  >
                    {I18n.t('home.prodForm.actions.previous')}
                  </button>
                }
                {
                    <button
                      type="submit"
                      className="btn btn--next"
                      disabled={this.props.currentStep === 4 ? submitting : false}
                    >
                      {
                        this.props.currentStep === 4 ? I18n.t('home.prodForm.actions.send') : I18n.t('home.prodForm.actions.next')
                      }
                    </button>
                }
              </div>
            </form>
          )}
        />
      </div>
    )
  }
}

export default connect(state => ({
  resources: state.home.resources,
}))(ProductionForm)
