// @flow

import React, {Fragment} from 'react';
import {I18n} from 'react-redux-i18n';
import {withRouter} from "react-router-dom";
import virtualAdvisorApi from "../../network/api/VirtualAdvisorApi";
import homeApi from "../../network/api/HomeApi";
import {notifyError} from "../../network/notification";
import type {VirtualAdvisorConnectionBean} from "../../types/VirtualAdvisorTypes";

type Props = {
  match: {
    params: Object
  },
  host: {
    url: string,
  }
}

type State = {
  host: {
    url: string,
  }
}

const MainModal = () => (
  <Fragment>
    <div
      className={`layer layer--active`}
    />
    <div className={`modal-protelis modal-protelis--active`}>
      <div className="header-image">
        <div className="logo-protelis"/>
      </div>
      <div className={'container-prod-form'}>
        { I18n.t('')}
      </div>
    </div>
  </Fragment>
);

class VirtualAdvisorConnect extends React.Component<Props, State> {
  state = {
    host: {
      url: '',
    }
  };

  connect = () => {
    virtualAdvisorApi.connect(this.props.match.params.token)
      .then(response => response.json())
      .then(this.insertLoginInformation)
      .catch(() => window.location = '/')
  };

  insertLoginInformation = (response: VirtualAdvisorConnectionBean) => {
    sessionStorage.setItem('authorizationToken', response.connectionToken);
    let currentUser = decodeURIComponent(escape(atob(response.connectionToken.split('.')[1])));
    // currentUser.mail = response.emailAddress;
    sessionStorage.setItem(
      'currentUser',
      currentUser,
    );
    sessionStorage.setItem(
      'currentUserMail',
      response.emailAddress,
    );
    window.location.href = `${this.state.host.url}/admin`;
  };

  componentDidMount() {
    homeApi.getAdminHost()
      .then(response => response.json())
      .then(host => {
        this.setState({ host });
      })
      .then(this.connect)
      .catch(notifyError(this.props.dispatch))
  }

  render() {
    return (
      <MainModal />
    )
  }
}

export default withRouter(VirtualAdvisorConnect);
