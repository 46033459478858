// @flow

import { RestClient } from '../network';

const virtualAdvisorApi = {
  connect: (token: string) =>
    new RestClient(`/virtualAdvisor/${token}`, 'GET').execute(),
};

export default virtualAdvisorApi;
