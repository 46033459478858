import React from 'react';
import FormQuestions from "./FormQuestions";
import {I18n} from "react-redux-i18n";
import {Form} from "react-final-form";
import {checkMailValidity} from "../../../utils";

type Props = {
  onSubmit: function,
  initialValues: Object
}
const validate = (values) => {
  const errors = {};

  if (!checkMailValidity(values.mail)) {
    errors.global = 'L\'email saisi est invalide';
    errors.mail = 'L\'email saisi est invalide';
  }

  return errors;
};

const ExtranetForm = ({onSubmit, initialValues} : Props) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    validate={validate}
    render={({handleSubmit, submitting, touched, form, errors, values}) => (
      <form onSubmit={handleSubmit} className="prod-form" style={{height: 400}}>
        <div className="content-form flex-column">
          <FormQuestions form={form} errors={errors} touched={touched} values={values}/>
        </div>

        <div className="actions">
          <button
            type="submit"
            className="btn btn--next"
            disabled={submitting}
          >
            {
              I18n.t('home.prodForm.actions.send')
            }
          </button>
        </div>
      </form>
    )}
  />
);

export default ExtranetForm;
