// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';

type Props = {
  dispatch?: Function,
  toggleVideo: Function,
  toggleLogin: Function,
}
type State = {
  headerClass: boolean,
}

class Header extends React.Component<Props, State> {
  state = {
    headerClass: false,
  };

  toggleHeaderClass = (headerClass: boolean) => this.setState({headerClass});

  componentDidMount() {
    const app = document.getElementById('main-content');
    app && app.addEventListener('scroll', () => {
      let value = app.scrollTop || 0;
      this.toggleHeaderClass(value >= (this.header && this.header.offsetHeight));
    }, false);
  }

  header: HTMLElement;

  render() {
    return (
      <header className="background" ref={el => this.header = el}>
        <div className={`blur ${this.state.headerClass ? 'blur--plain' : ''}`}>
          <div className="logo-protelis logo-protelis--baseline"/>
          <button className="btn btn--yellow" onClick={() => this.props.toggleLogin(true)}>{I18n.t('actions.CONNECT')}</button>
        </div>
        <div className="container-title">
          <div className="logo-protelis"/>
          <div className="explanation">{I18n.t('home.header.smoothIncomes')}</div>
        </div>
        <button
          className="btn btn--video"
          onClick={() => this.props.toggleVideo(true)}
        >{I18n.t('home.header.seeVideo')}</button>
      </header>
    )
  }
}
export default Header
