// @flow

import React from 'react';
import InitPassword from './components/InitPassword';
import PasswordApi from '../../network/api/PasswordApi';

type Props = {
  match: {
    params: Object
  }
}

type State = {
  isTokenValid: ?boolean,
  errorMessage: string
}

class PasswordManagement extends React.Component<Props, State> {

  state = {
    isTokenValid: null,
    errorMessage: '',
  };

  componentDidMount = () => {
    PasswordApi.isTokenValid(this.props.match.params.token)
      .then(response => response.json())
      .then((responseJson) => {
        this.setState({
          isTokenValid: responseJson,
        });
      });
  };

  props: Props;

  render() {
    if (this.state.isTokenValid === null) {
      return false;
    }
    return (
      <div className="user-data-page">
        <InitPassword
          token={this.props.match.params.token}
          isTokenValid={this.state.isTokenValid}
        />
      </div>
    )
  }
}

export default PasswordManagement
