// @flow

import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';


const findStatus = (step: number, currentStep: number) : string =>  {
  if (currentStep > step) {
    return 'step--past';
  } else if (currentStep < step) {
    return 'step--future';
  } else if (currentStep === step) {
    return 'step--current';
  } else {
    return '';
  }
};

const StepBar = ({step, currentStep}) => (
    <div className="bar"/>
  );

function StepContainer({currentStep, values, resources}) {
  if (!resources) {
    return false;
  }
  return (
    <div className="step-container">
      <div className={`step ${findStatus(1, currentStep)}`}>
        <div className="label">{values.region && resources.regions.find(reg => reg.value === values.region).label || I18n.t('steps.step1')}</div>
        <StepBar />
      </div>
      <div className={`step ${findStatus(2, currentStep)}`}>
        <div className="label">
          {
            values.cooperative.length !== 0 ?
              resources.cooperatives.find(coop => coop.value === values.cooperative[0]).label :
              I18n.t('steps.step2')
          }
        </div>
        <StepBar />
      </div>
      <div className={`step ${findStatus(3, currentStep)}`}>
        <div className="label">{
          values.productions.length !== 0 ?
            `${resources.productions
              .filter(prod => values.productions.includes(prod.value))
              .map(res => res.label).slice(0, 2).join(", ")}${values.productions.length > 2 ? ', ...' : ''}` :
            I18n.t('steps.step3')}</div>
        <StepBar />
      </div>
      <div className={`step ${findStatus(4, currentStep)}`}>
        <div className="label">{I18n.t('steps.step4')}</div>
        <StepBar />
      </div>
    </div>
  )
}

export default connect(state => ({
  resources: state.home.resources,
}))(StepContainer)