// @flow
export const HOME_PAGE_ROUTE = '/home';
export const PRECAUTIONARY_SAVINGS = '/epargne_precaution';
export const CONTACT_FORM = '/formulaire';

export const PASSWORD = '/password';

export const PASSWORD_PAGE = {
  INIT: `${PASSWORD}/init/:token`,
  RESET: `${PASSWORD}/reset`,
};

export const EMAIL_CHANGE_CONFIRM = '/email/update/:token';

export const EXTRANET = '/extranet/:token';
export const VIRTUAL_ADVISOR_CONNECT = '/virtualAdvisor/:token';
