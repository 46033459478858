// @flow

import React from 'react';
import { REQUIRED_FIELD } from '../../const';

type Props = {
  input: {
    value: string,
    onChange: Function,
  },
  label?: string,
  type: string,
  placeholder?: string,
  meta: Object,
  customError: string,
  onKeyPress: Function
}

type State = {
  password: boolean,
  icon: string,
}

export default class InputPro extends React.Component<Props, State> {
  state = {
    password: this.props.type === 'password'
  };

  toggleInputType = () => {
    this.setState(prevState => ({ password: !prevState.password }))
  };

  isPassword = () => this.state.password ? 'password' : 'text';

  render() {
    const { label, type, input, placeholder, meta, disabled, onKeyPress } = this.props;
    const { password } = this.state;
    return (
      <div className="container-input-pro">
        {
          label &&
          <div className="label">{label}</div>
        }
        <div className="wrap-icon">
          <input
            type={this.isPassword()}
            {...input}
            onKeyPress={onKeyPress}
            disabled={disabled}
            placeholder={placeholder || ''}
            className={`input-pro ${meta.touched && meta.error ? 'input-pro--error' : ''}`}
          />
          {
            type === 'password' &&
            <i
              onClick={this.toggleInputType}
              className={`icon-input ${password ? `fa fa-eye-slash` : 'fa fa-eye'}`}
            />
          }
        </div>
        {
          meta.error && meta.touched && meta.error === REQUIRED_FIELD &&
          <div className="error-form error-form--field">{meta.error}</div>
        }
      </div>
    )
  }
}
