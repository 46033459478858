// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field } from 'react-final-form';
import SelectPro from '../../../../components/form/SelectPro';
import MultiSelectPro from '../../../../components/form/MultiSelectPro';
import InputPro from '../../../../components/form/InputPro';
import RadioPro from '../../../../components/form/RadioPro';
import type { DataHome } from '../../../../types/HomeTypes';
import MapFrance from '../../components/MapFrance';
import InputStruct from '../../../../components/form/InputStruct';
import { TypeStructure } from '../../../../const';

type Props = {
  currentStep: number,
  productions?: DataHome[],
  cooperatives?: DataHome[],
  regions?: DataHome[],
  structures?: DataHome[],
  errors?: Object,
  values?: Object,
  touched?: Object,
}
const findStatus = (step: number, currentStep: number): string => {
  if (currentStep > step) {
    return 'step--past';
  } else if (currentStep < step) {
    return 'step--future';
  } else if (currentStep === step) {
    return 'step--current';
  } else {
    return '';
  }
};

export const Step1 = ({ currentStep }: Props) => (
  <div className={`step step-1 ${findStatus(1, currentStep)}`}>
    {
      (currentStep === 1 || currentStep === 2) &&
      <div className="step-content step-content--map">
        <div className="title">{I18n.t('home.prodForm.step1.title')}</div>
        <div className="line-form line-form--center">
          <Field
            name="region"
            component={MapFrance}
          />
        </div>
      </div>
    }
  </div>
);
export const Step2 = ({ currentStep, cooperatives, values }: Props) => (
  <div className={`step step-2 ${findStatus(2, currentStep)}`}>
    {
      (currentStep === 1 || currentStep === 2 || currentStep === 3) &&
      <div className="step-content">
        <div className="title">{I18n.t('home.prodForm.step2.title')}</div>

        <div className="line-form line-form--center line-form--step-2">
          <Field
            component={RadioPro}
            name="cooperative"
            list={cooperatives && cooperatives.map((coop) => ({ value: coop.value, label: coop.label }))}
            maxVal={1}
          />
          {
            values && !!values.cooperative && values.cooperative[0] === '0' &&
            <Field
              component={InputPro}
              name="otherCooperative"
              label={I18n.t('home.prodForm.step2.otherCooperative')}
              type="text"
            />
          }
        </div>
      </div>
    }
  </div>
);
export const Step3 = ({ currentStep, productions }: Props) => (
  <div className={`step step-3 ${findStatus(3, currentStep)}`}>
    {
      (currentStep === 2 || currentStep === 3 || currentStep === 4) &&
      <div className="step-content">
        <div className="title">{I18n.t('home.prodForm.step3.title')}</div>
        <Field
          component={MultiSelectPro}
          name="productions"
          list={productions && productions.map((prod) => ({ value: prod.value, label: prod.label }))}
        />
      </div>
    }
  </div>
);
export const Step4 = ({ currentStep, structures, form, errors, touched, values }: Props) => (
  <div className={`step step-4 ${findStatus(4, currentStep)}`}>
    {
      (currentStep === 3 || currentStep === 4) &&
      <div className="step-content">
        <div className="title">{I18n.t('home.prodForm.step4.title')}</div>
        <div className="container-perso">
          <div className="line-form">
            <Field
              name="structureType"
              component={SelectPro}
              label={I18n.t('home.prodForm.step4.structureType')}
              list={structures && structures.map((struct) => ({ value: struct.value, label: struct.label }))}
            />
            <Field
              name="structureName"
              component={InputStruct}
              label={I18n.t('home.prodForm.step4.structureName')}
              type="text"
              struct={values && values.structureType !== TypeStructure.OTHER && values.structureType !== TypeStructure.INDIVIDUAL ? values.structureType : undefined}
            />

          </div>
          <div className="line-form">
            <Field
              name="lastName"
              component={InputPro}
              label={I18n.t('home.prodForm.step4.lastName')}
              type="text"
            />
            <Field
              name="firstName"
              component={InputPro}
              label={I18n.t('home.prodForm.step4.firstName')}
              type="text"
            />
          </div>

          <div className="line-form">
            <Field
              name="mail"
              component={InputPro}
              label={I18n.t('home.prodForm.step4.email')}
              type="text"
              customError={errors.global}
            />
            <Field
              name="phone"
              component={InputPro}
              label={I18n.t('home.prodForm.step4.phone')}
              type="text"
              customError={errors.global}
            />
          </div>

          <div className="line-form">
            <Field
              name="city"
              component={InputPro}
              label={I18n.t('home.prodForm.step4.city')}
              type="text"
            />
          </div>
          {
            errors && errors.global && (touched.mail || touched.phone) &&
            <div className="error-form error-form--global">{errors.global}</div>
          }
        </div>
      </div>
    }
  </div>
);
