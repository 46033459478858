// @flow

import React, {Fragment} from 'react';
import {I18n} from 'react-redux-i18n';
import {withRouter} from "react-router-dom";
import extranetApi from "../../network/api/ExtranetApi";
import ExtranetForm from "./components/ExtranetForm";
import InformationModal from "../home/modals/InformationModal";
import {INFO} from "../../const";

type Props = {
  match: {
    params: Object
  }
}

type State = {
  initialValues: Object,
  formCompleted: boolean,
  formError: boolean,
}

const ProtelisHeader = () => (
  <div className="header-image">
    <div className="logo-protelis"/>
    <div className="description">
      {I18n.t('home.modalForm.description')}
    </div>
  </div>
);

const MainModal = ({children}) => (
  <Fragment>
    <div
      className={`layer layer--active`}
    />
    <div className={`modal-protelis modal-protelis--active`}>
      {children}
    </div>
  </Fragment>
);

const FormContainer = ({onSubmit, initialValues}) => (
  <Fragment>
    <ProtelisHeader/>
    <div className={'container-prod-form'}>
      <ExtranetForm
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    </div>
  </Fragment>
);

const ConfirmationModal = ({formError}) => (
  <InformationModal
    status={formError ? INFO.ERROR : INFO.SUCCESS}
    title={
      formError ?
        I18n.t('home.modalInfo.errorTitle') :
        I18n.t('home.modalInfo.messageVirtualAssistant')
    }
    message={
      formError ?
        I18n.t('home.modalInfo.errorMessage') :
        I18n.t('home.modalInfo.virtualAdvisorContact')
    }
    messageTitle={
      formError ?
        '' :
        I18n.t('home.modalInfo.mailSentToWithoutMail')
    }
  />
);

class ExtranetContractRequest extends React.Component<Props, State> {
  state = {
    initialValues: {
      token: this.props.match.params.token,
    },
    formCompleted: false,
    formError: false,
  };

  onSubmit = (values) => {
    extranetApi.insertProspect(values)
      .then(() => this.setState({formCompleted: true}))
      .catch(() => this.setState({formCompleted: true, formError: true}))
  };

  checkToken = () => {
    extranetApi.checkToken(this.props.match.params.token)
      .catch(() => window.location = '/')
  };

  componentDidMount() {
    this.checkToken();
  }

  render() {
    const {formCompleted, formError} = this.state;
    return (
      <MainModal>
        {
          formCompleted ?
            <ConfirmationModal formError={formError}/> :
            <FormContainer
              onSubmit={this.onSubmit}
              initialValues={this.state.initialValues}
            />
        }
      </MainModal>
    )
  }
}

export default withRouter(ExtranetContractRequest);
