// @flow

import { reduce } from '../../network/reduce';
import type { FormValidated, GraphData, Resources } from '../../types/HomeTypes';

export type initialStateType = {
  stepForm: 1 | 2 | 3 | 4,
  formValidated: ?FormValidated,
  resources: ?Resources,
  graphData: ?GraphData,
  selectedProd: ?string,
}

export const initialHomeState: initialStateType = {
  stepForm: 1,
  formValidated: null,
  resources: null,
  graphData: null,
  selectedProd: null,
};

export const STEP_FORM = 'STEP_FORM';
export const FORM_VALIDATED = 'FORM_VALIDATED';
export const RESOURCES = 'RESOURCES';
export const GRAPH_DATA = 'GRAPH_DATA';
export const SELECTED_PROD = 'SELECTED_PROD';

const homeReducer = reduce(initialHomeState, {
  [STEP_FORM]: 'stepForm',
  [FORM_VALIDATED]: 'formValidated',
  [RESOURCES]: 'resources',
  [GRAPH_DATA]: 'graphData',
  [SELECTED_PROD]: 'selectedProd',
});

export default homeReducer;
