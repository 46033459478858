// @flow

import React, {Fragment} from 'react';

type Props = {
  modalActive: boolean,
  className?: ?string,
  children: React.Node,
  close: Function,
  closeColor?: string,
}

export default function ModalHome({modalActive, className, children, close, closeColor}: Props) {
  return (
    <Fragment>
      <div
        onClick={() => close()}
        className={`layer ${modalActive ? 'layer--active' : ''} ${className || ''}`}
      />
      <div className={`modal-protelis ${modalActive ? 'modal-protelis--active' : ''} ${className || ''}`}>
        <i
          onClick={() => close()}
          className={`close fa fa-times ${closeColor ? `close--${closeColor}` : ''}`}
        />
        {children}
      </div>
    </Fragment>
  )
}
