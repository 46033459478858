// @flow

import React from 'react';
import type { InfoEnum } from '../../../types/HomeTypes';
import { INFO } from '../../../const';

type Props = {
  status: InfoEnum,
  title: string,
  message: string,
  messageTitle?: ?string,
}

export default function InformationModal({status, title, message, messageTitle}: Props) {
  return (
    <div className="information-modal">
      <div className={`header header--${status}`}>
        {
          status === INFO.SUCCESS &&
          <i className={`icon fa fa-check-circle`}/>
        }
        {
          status !== INFO.SUCCESS &&
          <i className={`icon fa fa-exclamation-triangle`}/>
        }
        <div className="title">{title}</div>
      </div>
      <div className="message">
        {
          messageTitle &&
          <div className="title">{messageTitle}</div>
        }
        <div className="content">{message}</div>
      </div>
    </div>
  )
}

InformationModal.defaultProps = {
  status: "SUCCESS",
};
