// @flow

import React from 'react';
import type { DataHome } from '../../types/HomeTypes';

type Props = {
  input: {
    value: string[],
    onChange: Function,
  },
  list: Object[],
  maxVal?: number,
  meta: {
    error: string,
    touched: boolean,
  }
}

export default class MultiSelectPro extends React.Component<Props> {
  addItem = (newItem: string) => {
    const newList = this.props.input.value.slice();
    newList.push(newItem);
    this.props.input.onChange(newList);
  };
  removeItem = (item: string) => {
    const newList = this.props.input.value.slice();
    newList.splice(this.props.input.value.indexOf(item), 1);
    this.props.input.onChange(newList);
  };

  handleCheck = (item: DataHome[]) => {
    if (this.props.input.value.filter(region => region === item.value).length > 0) {
      this.removeItem(item.value);
    } else {
      if (this.props.maxVal) {
        if (this.props.input.value.length >= this.props.maxVal) {
          return
        } else {
          this.addItem(item.value);
        }
      } else {
        this.addItem(item.value);
      }
    }
  };

  render() {
    return (
      <>
        <div className="container-btn" {...this.props.input}>
          {
            this.props.list.map(item => (
              <button
                key={item.value}
                type="button"
                onClick={() => this.handleCheck(item)}
                className={`btn ${this.props.input.value.includes(item.value) ? 'btn--selected' : 'btn--white'}`}
              >
                {item.label}
              </button>
            ))
          }
        </div>
        {
          this.props.meta.error && this.props.meta.touched &&
          <div className="error-form error-form--global">{this.props.meta.error}</div>
        }
      </>
    );
  }
}


