// @flow

import { store } from '../../network/reduce';
import {
  FORM_VALIDATED,
  GRAPH_DATA,
  RESOURCES, SELECTED_PROD,
} from './homeReducer';
import type { Resources } from '../../types/HomeTypes';
import homeApi from '../../network/api/HomeApi';

export const setFormValidated = (formValidated: Object) =>
  (dispatch: Function) => {
    dispatch(store(FORM_VALIDATED, formValidated));
  };

export const setResources = (resources: Resources) =>
  (dispatch: Function) => {
    dispatch(store(RESOURCES, resources));
  };

export const updateCoop = (region: string) =>
  (dispatch: Function, getStore: Function) => {
    homeApi.getCoopByRegion(region)
      .then(response => response.json())
      .then(cooperatives => {
        const updatedResources = getStore().home.resources;
        dispatch(store(RESOURCES, {...updatedResources, cooperatives}));
      })
  };
export const updateProd = (region: string) =>
  (dispatch: Function, getStore: Function) => {
    homeApi.getProdByCoop(region)
      .then(response => response.json())
      .then(productions => {
        const updatedResources = getStore().home.resources;
        dispatch(store(RESOURCES, {...updatedResources, productions}));
      })
  };
export const getProductionData = (production: string) =>
  (dispatch: Function) => {
    dispatch(store(SELECTED_PROD, production));
    homeApi.getProductionData(production)
      .then(response => response.json())
      .then(graphData => {
        dispatch(store(GRAPH_DATA, graphData));
      })
  };
