// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { buildGraph } from './form/buildGraph';
import { PRODUCTIONS } from '../../../const';
import type { GraphData } from '../../../types/HomeTypes';

type Props = {
  selectedProd: string,
  graphData: GraphData,
  goToForm: Function,
}

const graph = buildGraph();
let nbYears = 0;

export default function InformationProdModal({ selectedProd, graphData, goToForm }: Props) {
  if (!selectedProd) {
    return false;
  }

  if (graphData &&
    graphData.protelisGraph && graphData.protelisGraph.date.filter(val => moment(val).month() === 1).length !== null) {
    nbYears = [...new Set(graphData.annualPrice.date.map(d => moment(d).format('YYYY')))].length;
  }

  return (
    <div className="info-prod-modal">
      <div className="title">{I18n.t('home.modalInfoProd.title', { selectedProd: PRODUCTIONS[selectedProd] })}</div>
      <div className="content">
        {
          graphData && nbYears !== 0 &&
          graphData.protelisGraph && graphData.protelisGraph.date.filter(val => moment(val).month() === 1).length !== null ?
            <div className="container-graph-prod">
              <Line
                data={graph.data(graphData)}
                options={graph.options(nbYears)}
              />
            </div>
            :
            <div className="no-data">
              <div>{I18n.t('home.modalInfoProd.noData')}</div>
            </div>
        }
        <div className="text">
          <div>{I18n.t(`home.modalInfoProd.common`)}</div>
          {/*{*/}
          {/*  selectedProd === 'LINEN' &&*/}
          {/*  <div className="paragraph">{I18n.t(`home.modalInfoProd.${selectedProd}_CONTEXT`)}</div>*/}
          {/*}*/}
          {/*<div className="paragraph">*/}
          {/*  {*/}
          {/*    selectedProd !== 'EGG' && selectedProd !== 'POULTRY' &&*/}
          {/*    <span className="example">{I18n.t('home.modalInfoProd.example')}</span>*/}
          {/*  }*/}
          {/*  <div>{I18n.t(`home.modalInfoProd.${selectedProd}`)}</div>*/}
          {/*</div>*/}
        </div>
      </div>
      <button
        className="btn btn--yellow"
        onClick={() => goToForm()}
      >
        {I18n.t('home.modalInfoProd.contactMe')}
      </button>
    </div>
  )
}
