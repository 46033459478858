// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';

export default function Separator() {
  return (
    <>
      <div className="container">
        <div className="bonus">{I18n.t('home.separator.pros')}</div>
        <div>
          <div className="wrapped-icon ">
            <i className="icon-s icon--economy-white" />
          </div>
          <div className="title">
            {I18n.t('home.separator.guarantee')}
          </div>
        </div>
        <div>
          <div className="wrapped-icon ">
            <i className="icon-s icon--bar-white" />
          </div>
          <div className="title">
            {I18n.t('home.separator.savingAlwaysPossible')}
          </div>
        </div>
      </div>
    </>
  )
}
