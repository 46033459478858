// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import Header from './components/Header';
import Part from './components/Part';
import Square from './components/Square';
import {
  getProductionData,
} from '../../state/home/homeService';
import ModalHome from '../../components/ModalHome';
import InformationModal from './modals/InformationModal';
import { INFO } from '../../const';
import ModalFormContent from './modals/ModalFormContent';
import type { FormValidated, GraphData } from '../../types/HomeTypes';
import InformationProdModal from './modals/InformationProdModal';
import VideoModal from './modals/VideoModal';
import LegalMentionsModal from './modals/LegalMentionsModal';
import { store } from '../../network/reduce';
import { GRAPH_DATA } from '../../state/home/homeReducer';
import HistoryModal from './modals/HistoryModal';
import Separator from './components/Separator';
import Part1 from './components/Part1';
import Part2 from './components/Part2';
import Part3 from './components/Part3';
import LoginForm from './modals/LoginForm';

type Props = {
  dispatch: Function,
  popinForm: boolean,
  popinVideo: boolean,
  popinInfoProd: boolean,
  popinLegal: boolean,
  popinHistory: boolean,
  stepForm: 1 | 2 | 3 | 4,
  formValidated: FormValidated,
  graphData: GraphData,
  selectedProd: string,
}

type State = {
  login: boolean,
  form: boolean,
  video: boolean,
  infoProd: boolean,
  legal: boolean,
  history: boolean,
}

class Home extends React.Component<Props, State> {
  state = {
    login: false,
    form: false,
    video: false,
    infoProd: false,
    legal: false,
    history: false,
  };

  toggleLogin = (value: boolean) => {
    this.setState({login: value});
  };
  toggleForm = (value: boolean) => {
    this.setState({form: value});
  };
  toggleVideo = (value: boolean) => {
    this.setState({video: value});
  };
  toggleInfoProd = (value: boolean) => {
    this.setState({infoProd: value});
  };
  toggleLegal = (value: boolean) => {
    this.setState({legal: value});
  };

  selectProd = (production: string) => {
    this.props.dispatch(store(GRAPH_DATA, {}));
    this.props.dispatch(getProductionData(production));
  };

  gotToForm = () => {
    this.toggleForm(true);
    this.toggleInfoProd(false);
    this.toggleLogin(false);
  };

  render() {
    return (
      <div>
       <ModalHome
          modalActive={this.state.login}
          close={() => this.toggleLogin(false)}
        >
          <LoginForm goToForm={this.gotToForm}/>
        </ModalHome>

        <ModalHome
          modalActive={this.state.form}
          close={() => {
            this.toggleForm(false);
          }}
        >
          {
            this.props.formValidated ?
              <InformationModal
                status={this.props.formValidated.status}
                title={
                  this.props.formValidated.status !== INFO.SUCCESS ?
                    I18n.t('home.modalInfo.errorTitle') :
                    this.props.formValidated.protelis ?
                      I18n.t('home.modalInfo.messageToProtelis') :
                      I18n.t('home.modalInfo.messageToCoop', { coop: this.props.formValidated.coop })
                }
                message={
                  this.props.formValidated.status !== INFO.SUCCESS ?
                    I18n.t('home.modalInfo.errorMessage') :
                    I18n.t('home.modalInfo.coopWillContact')
                }
                messageTitle={
                  this.props.formValidated.status !== INFO.SUCCESS ?
                    '' :
                    I18n.t('home.modalInfo.mailSentTo', { mail: this.props.formValidated.mail })
                }
              />
              :
              <>
                <div className="header-image">
                  <div className="logo-protelis" />
                  <div className="description">
                    {I18n.t('home.modalForm.description')}
                  </div>
                </div>
                <ModalFormContent />
              </>
          }
        </ModalHome>
        <ModalHome
          modalActive={this.state.video}
          close={() => this.toggleVideo(false)}
        >
          <VideoModal open={this.state.video} />
        </ModalHome>

        <ModalHome
          modalActive={this.state.legal}
          close={() => this.toggleLegal(false)}
        >
          <LegalMentionsModal />
        </ModalHome>

        <ModalHome
          modalActive={this.state.infoProd}
          close={() => this.toggleInfoProd(false)}
          closeColor="purple-protelis"
        >
          <InformationProdModal
            selectedProd={this.props.selectedProd}
            graphData={this.props.graphData}
            goToForm={this.gotToForm}
          />
        </ModalHome>

        <Header toggleVideo={this.toggleVideo} toggleLogin={this.toggleLogin} />

        <Part>
          <Part1 />
        </Part>

        <div className="separator">
          <Separator />
        </div>

        <Part>
          <Part2 />
        </Part>

        <Part reverse>
          <Part3 toggleForm={this.toggleForm} />
        </Part>

        <Part>
          <Square selectProd={this.selectProd} toggleInfoProd={() => this.toggleInfoProd(true)} />
        </Part>

        <footer className="footer">
          <div className="logo-protelis" />
          <div className="container-icon">
            <a href="http://natup.coop/" target="_blank">
              <i className="corpo icon--natup"/>
            </a>
            <a href="https://www.invivo-group.com/fr/bioline-group" target="_blank">
              <i className="corpo icon--bioline" />
            </a>
          </div>
          <a href="mailto:contact@protelis.fr" className="contact">
            {I18n.t('home.footer.contactUs')}
          </a>
          <button type="button" onClick={() => this.toggleLegal(true)} className="docs">
            {I18n.t('home.footer.legalMention')}
          </button>
        </footer>
      </div>
    )
  }
}

export default connect(
  state => ({
    popinForm: state.home.popinForm,
    popinVideo: state.home.popinVideo,
    popinInfoProd: state.home.popinInfoProd,
    popinLegal: state.home.popinLegal,
    popinHistory: state.home.popinHistory,
    stepForm: state.home.stepForm,
    formValidated: state.home.formValidated,
    graphData: state.home.graphData,
    selectedProd: state.home.selectedProd,
  })
)(Home)
