// @flow

export function checkMailValidity(mail: string) {
  const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(mail);
}
export function checkPhoneValidity(phone: string) {
  const regex = /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/;
  return regex.test(phone);
}

export function checkPasswordValidity(password: string) {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
  return regex.test(password);
}
