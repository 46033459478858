// @flow

import { RestClient } from '../network';

const PasswordApi = {
  initPassword: (token: string, data: Object) =>
    new RestClient(`/password/setPassword/${token}`, 'POST').jsonBody(data).execute(),
  isTokenValid: (token: string) =>
    new RestClient(`/password/isTokenValid/${token}`, 'GET').execute(),
};

export default PasswordApi;
