// @flow

import React from 'react';
import EmailApi from "../../network/api/EmailApi";
import EmailUpdateConfirmation from "./EmailUpdateConfirmation";
import { I18n } from 'react-redux-i18n';

type Props = {
    match: {
        params: Object
    }
}

type State = {
    isTokenValid: ?boolean,
    errorMessage: string,
    newEmailAddress: string,
}

class EmailManagement extends React.Component<Props, State> {

    state = {
        isTokenValid: null,
        errorMessage: '',
        newEmailAddress: '',
    };

    componentDidMount = () => {
        EmailApi.updateEmail(this.props.match.params.token)
            .then(response => response.json())
            .then((responseJson) => {
                this.setState({
                    isTokenValid: true,
                    newEmailAddress: responseJson.email
                });
            })
          .catch(() => {
              this.setState({
                  isTokenValid: false,
                  newEmailAddress: null
              });
          });
    };

    props: Props;

    render() {
        return (
            <div className="user-data-page">
                <EmailUpdateConfirmation
                    isTokenValid={this.state.isTokenValid}
                    newEmailAddress={this.state.newEmailAddress}
                />
            </div>
        )
    }
}

export default EmailManagement;
