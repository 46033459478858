// @flow

import { RestClient } from '../network';

const EmailApi = {
    updateEmail: (token: string) =>
        new RestClient(`/email/update/${token}`, 'POST').execute(),
    isTokenValid: (token: string) => {
        return new RestClient(`/email/isTokenValid/${token}`, 'GET').execute()
    }
};

export default EmailApi;