// @flow

import React from 'react';
import { RegionJSON, SvgElement } from './Regions';

type Props = {
  input: {
    value: ?string,
    onChange: Function
  },
  meta: Object,
}
type State = {
  hoveredRegion: string,
}
export default class MapFrance extends React.Component<Props, State> {
  state = {
    hoveredRegion: '',
  };

  setHoveredRegion = (region: string) => this.setState({hoveredRegion: region});
  render() {
    const selectedRegion = RegionJSON.find(reg => reg.value === this.props.input.value);
    return (
      <>
        <div className="selected-region">
          {
            this.props.input.value &&
            selectedRegion &&
            selectedRegion.label ||
          this.state.hoveredRegion
          }
        </div>
        <svg version="1.0" xmlns="&ns_svg;" width="325" height="325" viewBox="15 5 640 610"
             overflow="visible">
          <g id="Calque_1">
            <g>
              <g>
                {
                  RegionJSON.map(region => (
                    <SvgElement
                      key={region.value}
                      color="#34624c"
                      hoverColor="#6EAE90"
                      selectedColor="#dd9f23"
                      selected={this.props.input.value === region.value}
                      path={region.path}
                      value={region.value}
                      label={region.label}
                      onChange={this.props.input.onChange}
                      className="path-map"
                      onHover={this.setHoveredRegion}
                    />
                  ))
                }
              </g>
            </g>
          </g>
        </svg>
        {
          this.props.meta.error && this.props.meta.touched &&
          <div className="error-form error-form--map">{this.props.meta.error}</div>
        }
      </>
    )
  }
}
