// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';

export default function Part1() {
  return (
    <>
      <div className="title-cat">
        {I18n.t('home.part1.protelis')}
      </div>
      <div className="complement">{I18n.t('home.part1.protelisComplement')}</div>
      <div className="line">
        <div className="text-field green">
          <div>{I18n.t('home.part1.protelisAllowsTo')}</div>
          <div>{I18n.t('home.part1.buildSaving')}</div>
          <div>{I18n.t('home.part1.accessAdvantages')}</div>
          <div>{I18n.t('home.part1.smoothIncomes')}</div>
        </div>
        <div className="img agri" />
      </div>
      <div className="line">
        <div className="img cows" />
        <div className="text-field blue">
          <div>{I18n.t('home.part1.woreByCoop')}</div>
          <div>
            {I18n.t('home.part1.coopAccompany')}
          </div>
          <div>
            {I18n.t('home.part1.counselorHelp')}
          </div>
        </div>
      </div>
    </>
  )
}
