// @flow

import React, {Fragment} from 'react';
import {I18n} from 'react-redux-i18n';
import InputPro from "../../../components/form/InputPro";
import {Field} from "react-final-form";
import {required} from "../../../validators";

type Props = {
  errors?: Object,
  values?: Object,
  touched?: Object,
}

const FormQuestions = ({ errors }: Props) => {
  return (
    <Fragment>
      <div className="line-form">
        <Field
          name="firstName"
          component={InputPro}
          label={I18n.t('home.prodForm.step4.firstName')}
          validate={required}
          type="text"
        />
      </div>
      <div className="line-form">
        <Field
          name="lastName"
          component={InputPro}
          label={I18n.t('home.prodForm.step4.lastName')}
          validate={required}
          type="text"
        />
      </div>
      <div className="line-form">
        <Field
          name="mail"
          component={InputPro}
          label={I18n.t('home.prodForm.step4.email')}
          type="text"
          validate={required}
          customError={errors.global}
        />
      </div>
    </Fragment>
  )
};

export default FormQuestions;