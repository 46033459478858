// @flow

import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import InputPro from '../../../components/form/InputPro';
import homeApi from '../../../network/api/HomeApi';
import { notifyError } from '../../../network/notification';
import { HttpClient } from '../../../network/network';

type State = {
  sent: boolean,
  login: boolean,
  host: {
    url: string,
  }
}

type Props = {
  goToForm: Function,
  dispatch: Function,
  values: Object,
}

class LoginForm extends React.Component<Props, State> {
  state = {
    sent: false,
    login: true,
    host: {
      url: '',
    }
  };

  componentDidMount() {
    homeApi.getAdminHost()
      .then(response => response.json())
      .then(host => this.setState({ host }))
      .catch(notifyError(this.props.dispatch))
  }

  onSubmit = (credentials) => {
    new HttpClient('/admin/session', 'POST')
      .headers({ 'Content-Type': 'application/json' })
      .jsonBody(credentials)
      .execute()
      .then((response) => {
        response.text().then((responseText) => {
          sessionStorage.setItem('authorizationToken', responseText);
          sessionStorage.setItem(
            'currentUser',
            decodeURIComponent(escape(atob(responseText.split('.')[1]))),
          );
          window.location.href = `${this.state.host.url}/admin/dashboard`
          return false;
        });
      })
      .catch(notifyError(this.props.dispatch));
  };

  resetPassword = (email) => {
    homeApi.resetPassword(email);
    this.setState({sent: true});
  };

  toggleLoginAction = () => this.setState(prevState => ({login: !prevState.login}))

  render() {
    return (
      <div>
        <div className="information-modal">
          <div className={`header header--SUCCESS header--minus`}>
            <div className="title">{`${this.state.login ? I18n.t('home.login.title') : I18n.t('home.login.resetPassword')}`}</div>
          </div>
        </div>
        <div className="login">
          {
            this.state.login &&
            <div className="explanation">
              {I18n.t('home.login.explanation')}
              <button onClick={() => this.props.goToForm()}>{I18n.t('home.login.form')}</button>
            </div>
          }
          {
            this.state.login &&
            <Form
              onSubmit={this.onSubmit}
              initialValues={{
                ...this.props.values,
              }}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} className="contract-form">
                  <Field
                    name="userName"
                    component={InputPro}
                    label={I18n.t('home.login.userName')}
                    type="text"
                    validate={value => value ? undefined : 'Champ requis'}
                  />
                  <Field
                    name="password"
                    component={InputPro}
                    label={I18n.t('home.login.password')}
                    type="password"
                    validate={value => value ? undefined : 'Champ requis'}
                  />
                  <div className="actions">
                    <button
                      type="submit"
                      className="btn btn--next"
                      disabled={invalid}
                    >
                      {I18n.t('actions.CONNECT')}
                    </button>
                    <div className="forgot" onClick={this.toggleLoginAction}>{I18n.t('home.login.forgotPassword')}</div>
                  </div>
                </form>
              )}
            />
          }
          {
            !this.state.login &&
            (
              !this.state.sent ?
                <Form
                  onSubmit={this.resetPassword}
                  initialValues={{
                    ...this.props.values,
                  }}
                  render={({ handleSubmit, invalid }) => (
                    <form onSubmit={handleSubmit} className="contract-form">
                      <Field
                        name="email"
                        component={InputPro}
                        label={I18n.t('home.login.email')}
                        type="text"
                        validate={value => value ? undefined : 'Champ requis'}
                      />
                      <div className="actions">
                        <button
                          type="submit"
                          className="btn btn--next"
                          disabled={invalid}
                        >
                          {I18n.t('home.login.getMail')}
                        </button>
                        <div className="forgot" onClick={this.toggleLoginAction}>{I18n.t('actions.CONNECT')}</div>
                      </div>
                    </form>
                  )}
                /> :
                  <div>
                      <br/>
                      <div>{I18n.t('home.login.emailSent')}</div>
                      <br/>
                      <button
                          type="button"
                          className="btn btn--yellow"
                          onClick={()=>{this.setState({sent:false, login:true})}}
                      >
                          {I18n.t('actions.BACK')}
                      </button>
                      <br/>
                  </div>
            )
          }
        </div>
      </div>
    )
  }
}

export default connect()(LoginForm);
