// @flow

import React from 'react';
import type { DataHome } from '../../types/HomeTypes';

type Props = {
  input: {
    value: string[],
    onChange: Function,
  },
  list: Object[],
  meta: {
    error: string,
    touched: boolean,
  }
}

export default class RadioPro extends React.Component<Props> {
  handleCheck = (item: DataHome) => {
    this.props.input.onChange([item.value]);
  };

  render() {
    return (
      <>
        <div className="container-btn" {...this.props.input}>
          {
            this.props.list.map(item => (
              <button
                key={item.value}
                type="button"
                onClick={() => this.handleCheck(item)}
                className={`btn ${this.props.input.value.includes(item.value) ? 'btn--selected' : 'btn--white'}`}
              >
                {item.label}
              </button>
            ))
          }
        </div>
        {
          this.props.meta.error && this.props.meta.touched &&
          <div className="error-form error-form--global">{this.props.meta.error}</div>
        }
      </>
    );
  }
}


