// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';

type Props = {
  open: boolean,
}

export default function VideoModal({ open }: Props) {
  return (
    <>
      {
        open &&
        <>
          <div className="information-modal">
            <div className={`header header--SUCCESS header--minus`}>
              <div className="title">{I18n.t('home.modalVideo.title')}</div>
            </div>
          </div>
          <div className="container-video">
            <iframe
              src="https://www.youtube.com/embed/ATCpRGBG28A"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </>
      }

    </>
  )
}
