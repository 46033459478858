// @flow

import React from 'react';

type Props = {
  children: React.Node,
  reverse?: boolean,
}

export default class Part extends React.Component<Props> {
  render() {
    return (
      <div className={`part ${this.props.reverse ? 'part--reverse' : ''}`}>
        <div className="content">
          {this.props.children}
        </div>
      </div>
    )
  }
}
