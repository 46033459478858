// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import PasswordApi from '../../../network/api/PasswordApi';
import { notifyError } from '../../../network/notification';
import { HttpClient } from '../../../network/network';
import homeApi from '../../../network/api/HomeApi';
import { Field, Form } from 'react-final-form'
import InputPro from '../../../components/form/InputPro'
import { required } from '../../../validators';
import type { Credentials } from '../../../types/HomeTypes';
import { checkPasswordValidity } from '../../../utils';

type Props = {
  token: string,
  dispatch: Function
}

type State = {
  isTokenValid: boolean,
  error: ?string,
  host: {
    url: string,
  }
}

class InitPassword extends React.Component<Props, State> {

  state = {
    isTokenValid: false,
    error: null,
    host: {
      url: '',
    }
  };

  componentDidMount() {
    homeApi.getAdminHost()
      .then(response => response.json())
      .then(host => {
        this.setState({ host });
      })
      .catch(notifyError(this.props.dispatch))
  }

  handleSubmit = (values: Object) => {
    const { newPassword, newPasswordConfirmation } = values;
    if (newPasswordConfirmation !== newPassword) {
      this.setState({ error: 'Les mots de passe sont différents' });
      return;
    }
    if (!checkPasswordValidity(newPasswordConfirmation) || !checkPasswordValidity(newPassword)) {
      this.setState({ error: I18n.t('error.passwordValidity') });
      return;
    }
    if (newPassword) {
      PasswordApi.initPassword(this.props.token, { newPassword: newPassword })
        .then(response => response.json())
        .then((responseJson) => {
          this.setState({
          });
          this.handleRedirection({ userName: responseJson.userName, password: newPassword })
        })
        .catch(error => error.then(() => {
          this.setState({
            isTokenValid: false,
            errorMessage: I18n.t('wsError.TOKEN_NOT_VALID'),
          });
        }));
    }
  };

  handleRedirection = (credentials: Credentials) => {
    new HttpClient('/admin/session', 'POST')
      .headers({ 'Content-Type': 'application/json' })
      .jsonBody(credentials)
      .execute()
      .catch(notifyError(this.props.dispatch))
      .then((response) => {
        response.text().then((responseText) => {
          sessionStorage.setItem('authorizationToken', responseText);
          sessionStorage.setItem(
            'currentUser',
            decodeURIComponent(escape(atob(responseText.split('.')[1]))),
          );
          window.location.href = `${this.state.host.url}/admin`
          return false;
        });
      })
      .catch(notifyError(this.props.dispatch));
  };

  render() {
    return (
      <header className="background-user-data" ref={el => this.header = el}>
        <div className={`blur ${this.state.headerClass ? 'blur--plain' : ''}`}>
          <div className="logo-protelis logo-protelis--baseline" />
        </div>
        <div className="user-data-container">
          <div className="header">
            <div className="title">
              {I18n.t('password.title')}
            </div>
          </div>
          <div className="user-data-content">
            {
              this.props.isTokenValid ?
                <div className="login">
                  <Form
                    onSubmit={this.handleSubmit}
                    render={({ handleSubmit, invalid }) => (
                      <form
                        onSubmit={handleSubmit}
                        className="contract-form">
                        <Field
                          label={I18n.t('password.initialisePassword')}
                          component={InputPro}
                          name="newPassword"
                          validate={required}
                          type="password"
                        />
                        <Field
                          label={I18n.t('password.confirmedPassword')}
                          component={InputPro}
                          name="newPasswordConfirmation"
                          validate={required}
                          type="password"
                        />
                        {
                          this.state.error &&
                          <div className="error-form error-form--global">{this.state.error}</div>
                        }
                        <button
                          type="submit"
                          className="btn btn--yellow"
                        >{I18n.t('password.resetPasswordButton')}
                        </button>
                      </form>
                    )}
                  />
                </div>

                : <div className="alert alert-danger" role="alert">{I18n.t('password.errorLinkNotvalidOrExpired')}</div>
            }
          </div>
        </div>
      </header>
    )
  }
}

export default InitPassword
