// @flow

import { RestClient } from '../network';

const homeApi = {
  getResources: () => new RestClient(`/home/resources`, 'GET').execute(),

  getCoopByRegion: (region: string) => new RestClient(`/home/resources/cooperatives/${region}`, 'GET').execute(),

  getProdByCoop: (coop: string) => new RestClient(`/home/resources/productions/${coop}`, 'GET').execute(),

  createProspect: (prospect: Prospect) => new RestClient(`/home/prospect`, 'POST').jsonBody(prospect).execute(),

  getProductionData: (production: string) => new RestClient(`/home/resources/productions/graph/${production}`, 'GET').execute(),

  getAdminHost: () => new RestClient(`/home/host`, 'GET').execute(),

  resetPassword: (email) => new RestClient('/password/request', 'POST').jsonBody(email).execute(),
};

export default homeApi;
