// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';

export default function Part2() {
  return (
    <>
      <div className="title-cat">{I18n.t('home.part2.howDoesItWork')}</div>
      <div className="subtitle">{I18n.t('home.part2.protelisAdapted')}</div>
      <div className="container-graph">
        <div className="half">
          <div className="title">{I18n.t('home.part2.priceGraph')}</div>
          <div className="legends">
            <div className="legend">
              <i className="dot dot-green" />
              {I18n.t('home.part2.marketPrice')}
            </div>
            <div className="legend">
              <i className="dot dot-purple" />
              {I18n.t('home.part2.annualAverage')}
            </div>
            <div className="legend">
              <i className="dot dot-yellow" />
              {I18n.t('home.part2.yourPrice')}
            </div>
          </div>
          <div className="graph" />
        </div>
        <div className="half text-field">
          <div className="explanation">
            <div className="icon icon--euro-blue" />
            <div className="description">
              <div className="bold space">
                {I18n.t('home.part2.priceSup')}
              </div>
              <div className="bold">
                {I18n.t('home.part2.bold.create')}
              </div>
              {I18n.t('home.part2.precaution')}
            </div>

            <span className="dash"/>

            <div className="icon icon--euro-yellow" />
            <div className="description">
              <div className="bold space">
                {I18n.t('home.part2.priceInf')}
              </div>
              {I18n.t('home.part2.newText')}
            </div>
            <div className="conclusion">{I18n.t('home.part2.protelisSave')}</div>
          </div>
        </div>
      </div>
    </>
  )
}
