// @flow

import { INVALID_PASSWORD, REQUIRED_FIELD } from './const';
import React from 'react';

export const required = (value: ?string) => {
  return (value ? undefined : REQUIRED_FIELD);
}
export const isPasswordValid = (value: string) => (value && value.length > 8 ? undefined : INVALID_PASSWORD)

export const composeValidators = (...validators: Function[]) => (value: string) =>
  validators.reduce((error, validator) => error || validator(value), undefined);
