// @flow

import moment from 'moment';

export const dataSet = (pointStyle, label, graph, color, showLine, borderWidth, pointRadius, spanGaps) => ({
  type: 'line',
  hoverRadius: 12,
  pointStyle,
  label: label,
  fill: false,
  showLine,
  borderWidth,
  pointRadius,
  backgroundColor: color,
  borderColor: color,
  data: graph.price,
  spanGaps
})

export const buildGraph = () => ({
  data: (graphData) => ({
    labels: graphData.annualPrice.date,
    datasets: [
      dataSet('dash', 'Moyenne annuelle', graphData.averageGraph, '#34624c', false, 3, 10, true),
      dataSet('dash', 'Moyenne Protélis', graphData.protelisGraph, '#dd9f23', false, 3, 10, true),
      dataSet('circle', `Prix ${graphData.reference}`, graphData.annualPrice, '#33283d', true, 2, 0, true),
    ],
  }),

  options: (nbYears) => ({
    showLine: false,
    scales: {
      xAxes: [{
        display: true,
        type: 'time',
      }],
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      labels: {
        boxWidth: 45,
        boxHeight: 1,
        padding: 15
      }
    }
  })
});

