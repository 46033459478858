import React from 'react';

const fr = {
  example: {
    modalContent: 'Je suis une modale',
  },
  actions: {
    BACK: 'Retour',
    CANCEL: 'Annuler',
    SAVE: 'Enregistrer',
    DELETE: 'Supprimer',
    NEW: 'Nouveau',
    CONFIRM: 'Confirmer',
    SEARCH: 'Rechercher',
    CONNECT: 'Se connecter'
  },
  message: {
    SUCCESS: 'Les modifications ont bien été prises en compte',
    IN_PROGRESS: 'Votre fichier est valide, l\'import des données est en cours.',
    ERROR: 'Les modifications ont échouées',
    YES: 'Oui',
    NO: 'Non',
  },
  error: {
    passwordValidity: 'Le mot de passe doit contenir au moins 8 caractères dont au moins une majuscule/minuscule et un chiffre',
  },
  wsError: {
    INTERNAL_ERROR: 'Erreur de connexion',
    AUTHENTIFICATION_EXPIRED: 'Session d\'administration expirée',
    FIELD_REQUIRED: 'Le champ \'%{field0}\' est requis',
    COLOR_INVALID: 'Le champ \'%{field0}\' doit être une couleur au format hexadecimale : #ff00ff',
    EMAIL_INVALID: 'L\'adresse email \'%{field0}\' n\'est pas valide',
    USERNAME_ALREADY_EXISTS: 'Cet identifiant est déjà utilisé sur un autre compte, veuillez en choisir un autre',
    EMAIL_ALREADY_EXISTS: 'Cette adresse email est déjà utilisée sur un autre compte, veuillez en choisir une autre',
    PASSWORDS_DIFFERENT: 'Les mots de passe \'%{field0}\' et \'%{field1}\' doivent être identiques',
    WRONG_LOGIN_OR_PASSWORD: 'Nom d\'utilisateur ou mot de passe incorrect',
    TOO_MANY_WRONG_ATTEMPS: 'Suite à des erreurs dans la saisie de vos identifiants, votre compte est verrouillé pendant %{field0} minutes, veuillez-vous reconnecter ultérieurement',
    ROLE_LABEL_EXISTS: 'Il existe déjà un rôle avec ce libellé',
    PASSWORD_TOO_SHORT: 'Le mot de passe est trop court, il doit faire au moins %{field0} caractères',
    MESSAGE: '%{field0}',
    TOKEN_NOT_VALID: 'Votre lien a expiré',
  },
  date: {
    long: 'DD MMMM YYYY HH:mm',
    DATE_START: 'Date de début',
    DATE_END: 'Date de fin',
    DATE_FORMAT: 'DD/MM/YYYY',
  },
  steps: {
    step1: 'Étape 1',
    step2: 'Étape 2',
    step3: 'Étape 3',
    step4: 'Étape 4',
  },
  home: {
    protelisForYou: `Vous êtes agriculteur, adhérent d'une coopérative ? 
    La solution Protelis est faite pour vous !`,
    goToHome: 'Plus d\'infos sur protelis.fr',
    login: {
      emailSent: 'Un email de réinitialisation de mot de passe vous a été envoyé.',
      form: 'formulaire de contact !',
      explanation: 'Si vous avez déja souscrit à l\'offre Protélis, vous pouvez vous connecter, sinon remplissez le ',
      title: 'Se connecter à mon espace Protélis',
      resetPassword: 'Saisissez votre adresse email pour réinitialiser votre mot de passe.',
      password: 'Mot de passe',
      userName: 'Identifiant',
      email: 'Adresse email',
      getMail: 'Réinitialiser',
      forgotPassword: 'Mot de passe oublié ?',
    },
    modalInfo: {
      messageToCoop: 'Votre demande de contact a bien été envoyée à la coopérative %{coop}',
      messageVirtualAssistant: 'Votre demande de création de contrat a bien été prise en compte',
      messageToProtelis: 'Votre demande de contact a bien été envoyée à Protélis',
      coopWillContact: 'Le conseiller de votre coopérative va vous contacter dans les plus brefs délais.',
      virtualAdvisorContact: 'Votre demande a bien été mis en compte. Un email vous a été envoyé pour continuer.',
      mailSentTo: 'Un email récapitulatif de votre demande vous a été envoyé à l\'adresse %{mail}',
      mailSentToWithoutMail: 'Un email récapitulatif de votre demande vous a été envoyé',
      errorTitle: 'Une erreur est survenue',
      errorMessage: 'Vos informations n\'ont pas pu être enregistrées, veuillez réessayer ultérieurement',
    },
    modalForm: {
      description: 'Afin de vous mettre en relation avec votre coopérative, veuillez renseigner les informations suivantes.',
    },
    modalVideo: {
      title: 'Protélis en image !',
    },
    modalLegal: {
      title: 'Mentions légales',
    },
    modalHistory: {
      title: 'Qui sommes-nous ?',
      subtitle: 'Protélis est une solution simple et unique pour lisser et sécuriser ses revenus agricoles',
    },
    modalInfoProd: {
      title: 'Production de %{selectedProd}',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor,\n' +
        '                dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula\n' +
        '                massa, varius a, semper congue, euismod non, mi. Proin porttitor, orci nec nonummy molestie, enim est\n' +
        '                eleifend mi, non fermentum diam nisl sit amet erat. Duis semper. Duis arcu massa, scelerisque vitae,\n' +
        '                consequat in, pretium a, enim. Pellentesque congue. Ut in risus volutpat libero pharetra tempor. Cras\n' +
        '                vestibulum bibendum augue. Praesent egestas leo in pede. Praesent blandit odio eu enim. Pellentesque sed\n' +
        '                dui ut augue blandit sodales. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere\n' +
        '                cubilia Curae; Aliquam nibh. Mauris ac mauris sed pede pellentesque fermentum. Maecenas adipiscing ante\n' +
        '                non diam sodales hendrerit.',
      noData: 'Nous n\'avons pour le moment pas de données pour cette production',
      contactMe: 'Je souhaite être contacté pour une simulation personalisée',
      example: 'Un exemple pour bien comprendre',
      common: 'Conflits mondiaux, catastrophes météorologiques... tous ces facteurs imprévisibles peuvent entraîner des fluctuations brutales des prix des productions agricoles mais également des prix des intrants nécessaires aux cultures ou à l\'alimentation des animaux. Des « effets ciseaux » sont bien souvent observés et peuvent mettre en péril une entreprise agricole.\n\n' +
        'C\'est pour cela que depuis 2019, l\'État français a mis en place la Déduction pour Épargne de Précaution (DEP), permettant aux agriculteurs de lisser leur fiscalité. Mettre de côté les « bonnes années » pour être solides quand le contexte est plus difficile.\n\n' +
        'En 2019 a été également créé PROTÉLIS. PROTÉLIS est le système de DEP Coopérative. Chaque coopérative peut proposer ce service à ses adhérents en fonction des productions que ces derniers lui livrent.\n\n' +
        'Ainsi, une coopérative céréalière pourra proposer PROTÉLIS sur le blé à ses adhérents livreurs de blé, une coopérative laitière à ses adhérents livreurs de lait de vache ou de lait de chèvre, une coopérative porcine à ses adhérents éleveurs de porcs...\n\n' +
        '19 productions sont proposées aujourd\'hui.\n\n' +
        'L\'épargne est gérée par votre coopérative. Cette épargne est disponible à tout moment et suit les mêmes règles de réintégration que les autres systèmes de DEP en vigueur.\n',
      BEETS: 'Avec sa récolte 2022, un adhérent a livré 40 hectares de betteraves sucrières, soit un volume total de 2 800 tonnes à sa coopérative.\n\n' +
        'PROTELIS a constaté un écart de prix sur le marché de la pomme de terre qui permet de réaliser une mise en épargne de 11,12 €/tonne entre le 1er janvier et 31 décembre 2023.\n\n' +
        'L\'adhérent peut ainsi épargner avec sa coopérative selon ses besoins jusqu\'à 31 136 € (2800 t x 11,12 €), ouvrant droit à une DEP pouvant aller jusqu\'à 62 272 €.\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou à une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale car le potentiel autorisé avec PROTELIS peut aller bien au-delà de ce que le producteur pourra réaliser.\n',
      BLE: 'Avec sa récolte 2022, un adhérent a livré 150 tonnes de blé tendre à sa coopérative.\n\n' +
        'PROTELIS a constaté un écart de prix sur le marché du blé tendre qui permet de réaliser une mise en épargne de 119,63 €/tonne entre le 1er janvier et 31 décembre 2023.\n\n' +
        'L\'adhérent peut ainsi épargner avec sa coopérative selon ses besoins jusqu\'à 17 944 € (150 t x 119,63 €), ouvrant droit à une DEP pouvant aller jusqu\'à 35 888 €.\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou à une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale car le potentiel autorisé avec PROTELIS peut aller bien au-delà de ce que le producteur pourra réaliser.\n',
      RAPE: 'Avec sa récolte 2022, un adhérent a livré 50 tonnes de colza à sa coopérative.\n\n' +
        'PROTELIS a constaté un écart de prix sur le marché du colza qui permet de réaliser une mise en épargne de 187,93 €/tonne entre le 1er janvier et 31 décembre 2023.\n\n' +
        'L\'adhérent peut ainsi épargner avec sa coopérative selon ses besoins jusqu\'à 9 396 € (50 t x 187,93 €), ouvrant droit à une DEP pouvant aller jusqu\'à 18 792 €.\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou à une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale car le potentiel autorisé avec PROTELIS peut aller bien au-delà de ce que le producteur pourra réaliser.\n',
      MILK: 'Un adhérent a livré à sa coopérative 750 000 litres de lait de vache conventionnel entre 1 novembre 2021 et le 31 octobre 2022.\n\n' +
        'PROTELIS a constaté un écart de prix sur le marché du lait de vache conventionnel qui permet de réaliser une mise en épargne de 72,10 €/ 1000 litres de lait entre le 1er janvier et 31 décembre 2023.\n\n' +
        'L\'adhérent peut ainsi épargner avec sa coopérative selon ses besoins jusqu\'à 54 075 € (750 x 72,10 €), ouvrant droit à une DEP pouvant aller jusqu\'à 108 150 €.\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale car le potentiel autorisé avec PROTELIS peut aller bien au-delà de ce que le producteur pourra réaliser.\n',
      CORN: 'Avec sa récolte 2022, un adhérent a livré 90 tonnes de maïs grain à sa coopérative.\n\n' +
        'PROTELIS a constaté un écart de prix sur le marché du maïs grain qui permet de réaliser une mise en épargne de 122,84 €/tonne entre le 1er janvier et 31 décembre 2023.\n\n' +
        'L\'adhérent peut ainsi épargner avec sa coopérative selon ses besoins jusqu\'à 11 055 € (90 t x 122,84 €), ouvrant droit à une DEP pouvant aller jusqu\'à 22 110 €.\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou à une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale car le potentiel autorisé avec PROTELIS peut aller bien au-delà de ce que le producteur pourra réaliser.\n',
      POTATOE: 'Avec sa récolte 2022, un adhérent a livré 200 tonnes de pomme de terre à sa coopérative.\n\n' +
        'PROTELIS a constaté un écart de prix sur le marché de la pomme de terre qui permet de réaliser une mise en épargne de 90,11 €/tonne entre le 1er janvier et 31 décembre 2023.\n\n' +
        'L\'adhérent peut ainsi épargner avec sa coopérative selon ses besoins jusqu\'à 18 022 € (200 t x 90,11 €), ouvrant droit à une DEP pouvant aller jusqu\'à 36 044 €.\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou à une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale car le potentiel autorisé avec PROTELIS peut aller bien au-delà de ce que le producteur pourra réaliser.\n',
      PORK: 'Un adhérent a livré à sa coopérative 4000 porcs charcutiers sur l\'année 2022, d\'un poids moyen de 94 kg par animal, soit 376 000 kg.\n\n' +
        'PROTELIS a constaté un écart de prix sur le marché du porc breton qui permet de réaliser une mise en épargne de 0,32 €/kilo entre le 1er janvier et 31 décembre 2023.\n\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale car le potentiel autorisé avec PROTELIS peut aller bien au-delà de ce que le producteur pourra réaliser.\n',
      BARLEY: 'Avec sa récolte 2022, un adhérent a livré 100 tonnes d\'orge à sa coopérative.\n\n' +
        'PROTELIS a constaté un écart de prix sur le marché de l\'orge qui permet de réaliser une mise en épargne de 100,31 €/tonne entre le 1er janvier et 31 décembre 2023.\n\n' +
        'L\'adhérent peut ainsi épargner avec sa coopérative selon ses besoins jusqu\'à 10 031 € (100 t x 100,31 €), ouvrant droit à une DEP pouvant aller jusqu\'à 20 062 €.\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou à une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale car le potentiel autorisé avec PROTELIS peut aller bien au-delà de ce que le producteur pourra réaliser.\n',
      BLE_HARD: 'Avec sa récolte 2022, un adhérent a livré 50 tonnes de blé dur à sa coopérative.\n\n' +
        'PROTELIS a constaté un écart de prix sur le marché du blé dur qui permet de réaliser une mise en épargne de 161,55 €/tonne entre le 1er janvier et 31 décembre 2023.\n\n' +
        'L\'adhérent peut ainsi épargner avec sa coopérative selon ses besoins jusqu\'à 8 077 € (50 t x 161,55 €), ouvrant droit à une DEP pouvant aller jusqu\'à 16 154 €.\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou à une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale car le potentiel autorisé avec PROTELIS peut aller bien au-delà de ce que le producteur pourra réaliser.\n',
      BIO_MILK: 'Un adhérent a livré à sa coopérative 300 000 litres de lait de vache bio entre 1 novembre 2021 et le 31 octobre 2022.\n\n' +
        'PROTELIS a constaté un écart de prix sur le marché du lait de vache bio qui permet de réaliser une mise en épargne de 1,51 €/ 1000 litres de lait entre le 1er janvier et 31 décembre 2023.\n\n' +
        'L\'adhérent peut ainsi épargner avec sa coopérative selon ses besoins jusqu\'à 453 € (300 x 1,51 €), ouvrant droit à une DEP pouvant aller jusqu\'à 906 €.\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale.\n',
      MILK_GOAT: 'Un adhérent a livré à sa coopérative 100 000 litres de lait de chèvre entre 1 novembre 2021 et le 31 octobre 2022.\n\n' +
        'PROTELIS a constaté un écart de prix sur le marché du lait de chèvre qui permet de réaliser une mise en épargne de 75,16 €/ 1000 litres de lait entre le 1er janvier et 31 décembre 2023.\n\n' +
        'L\'adhérent peut ainsi épargner avec sa coopérative selon ses besoins jusqu\'à 7 516 € (100 x 75,16 €), ouvrant droit à une DEP pouvant aller jusqu\'à 15 032 €.\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale car le potentiel autorisé avec PROTELIS peut aller bien au-delà de ce que le producteur pourra réaliser.\n',
      EGG: 'Un adhérent a livré à sa coopérative 5 000 000 d\'œufs issus de ses 25 000 pondeuses sur l\'année 2022.\n\n' +
        'PROTELIS a constaté un écart de prix sur le marché de l\'œuf (Min de Rungis) qui permet de réaliser une mise en épargne de 4,75 €/ 100 œufs entre le 1er janvier et 31 décembre 2023.\n\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale car le potentiel autorisé avec PROTELIS peut aller bien au-delà de ce que le producteur pourra réaliser.\n',
      LINEN_CONTEXT: 'Le Lin Textile est une culture particulière. De la plante de lin, plusieurs types de production seront valorisés aux liniculteurs : le lin teillé, les étoupes et pour certains la semence.\n' +
        'Le choix a été de travailler uniquement sur le lin teillé. Autre particularité de cette production, sa valorisation complète pour le producteur sera réalisée sur plus d\'un an. En conséquence, les volumes et prix d\'une récolte produiront le potentiel de DEP Coopérative en N+2, ce qui explique le petit décalage que l\'observe sur le graphique.\n',
      LINEN: 'Sur la récolte 2021, un adhérent cultive 15 hectares de lin textile. Il aura livré au final, après travail de son lin par la coopérative, 33 370 Kg de lin teillé.\n\n' +
        'PROTELIS a constaté un écart de prix sur le marché du lin teillé qui permet de réaliser une mise en épargne de 0,39 €/kilo entre le 1er janvier et 31 décembre 2023.\n\n' +
        'L\'adhérent peut ainsi épargner avec sa coopérative selon ses besoins jusqu\'à 13 053 € (33 370 kg x 0,39 €), ouvrant droit à une DEP pouvant aller jusqu\'à 26 106 €.\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou à une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale car le potentiel autorisé avec PROTELIS peut aller bien au-delà de ce que le producteur pourra réaliser.\n',
      COW_MEAT: 'Un adhérent a livré 52 bovins avec un poids moyen de 420 kg par animal, soit 21 840 kg de viande sur l\'année 2022.\n\n' +
        'Avec PROTELIS, l\'écart de prix constaté sur le marché de la viande bovine permet de réaliser une mise en épargne de 1,19 €/ kg de viande entre le 1er janvier et 31 décembre 2023.\n\n' +
        'L\'adhérent peut ainsi épargner avec sa coopérative selon ses besoins jusqu\'à 25 989 € (21 840 x 1,19), ouvrant droit à une DEP de 51 542 €.\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou à une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale car le potentiel autorisé avec PROTELIS peut aller bien au-delà de ce que le producteur pourra réaliser.\n',
      CARROT: 'Avec sa récolte 2022, un adhérent a livré 80 tonnes de carotte à sa coopérative.\n\n' +
        'PROTELIS a constaté un écart de prix sur le marché de la carotte qui permet de réaliser une mise en épargne de 174.33 €/tonne entre le 1er janvier et 31 décembre 2023.\n\n' +
        'L\'adhérent peut ainsi épargner avec sa coopérative selon ses besoins jusqu\'à 13 946 € (80 t x 174.33 €), ouvrant droit à une DEP pouvant aller jusqu\'à 27 892 €.\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou à une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale car le potentiel autorisé avec PROTELIS peut aller bien au-delà de ce que le producteur pourra réaliser.\n',
      WINE: 'Prenons l\'exemple d\'un adhérent livrant 1 250 hectolitres de vin à la coopérative lors des vendanges 2022.\n\n' +
        'Avec PROTELIS, l\'écart de prix constaté sur le marché du vin permet de réaliser une mise en épargne de 3.22 €/ hectolitre de vin entre le 1er janvier et 31 décembre 2023.\n\n' +
        'L\'adhérent peut ainsi épargner avec sa coopérative selon ses besoins jusqu\'à 4 025 €, ouvrant droit à une DEP de 8 050 €.\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative.\n' +
        'La date de déclenchement de la mise en épargne se fera selon les règles établies par la réglementation.\n',
      SUNFLOWER: 'Avec sa récolte 2022, un adhérent a livré 50 tonnes de tournesol à sa coopérative.\n\n' +
        'PROTELIS a constaté un écart de prix sur le marché du tournesol qui permet de réaliser une mise en épargne de 231,46 €/tonne entre le 1er janvier et 31 décembre 2023.\n\n' +
        'L\'adhérent peut ainsi épargner avec sa coopérative selon ses besoins jusqu\'à 11 573 € (50 t x 231,46 €), ouvrant droit à une DEP pouvant aller jusqu\'à 23 146 €.\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou à une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale car le potentiel autorisé avec PROTELIS peut aller bien au-delà de ce que le producteur pourra réaliser.\n',
      MUTTON: 'Un adhérent a livré 520 agneaux avec un poids moyen de 20 kg par animal, soit 10 400 kg de viande sur l\'année 2022.\n\n' +
        'Avec PROTELIS, l\'écart de prix constaté sur le marché de la viande ovine permet de réaliser une mise en épargne de 1,17 €/ kg de viande entre le 1er janvier et 31 décembre 2022.\n\n' +
        'L\'adhérent peut ainsi épargner avec sa coopérative selon ses besoins jusqu\'à 12 168 € (10 400 x 1,17), ouvrant droit à une DEP de 24 336 €.\n' +
        'Cette mise en épargne peut être complétée si nécessaire grâce à une autre production livrée à sa coopérative ou à une autre coopérative.\n' +
        'Le choix du montant et la date de déclenchement de la mise en épargne se feront selon les règles établies par la réglementation fiscale car le potentiel autorisé avec PROTELIS peut aller bien au-delà de ce que le producteur pourra réaliser.\n',
      POULTRY: 'Face à l\'accélération du nombre de foyers d\'influenza aviaire confirmés en France, le contexte économique de cette filière est tendu voir dramatique pour de nombreux élevages impactés.\n\n' +
        'Il semble compliquer dans ce contexte de parler de Déduction pour Epargne de Précaution.\n\n' +
        'Le système de DEP Coopérative Protélis a cependant calculé un écart positif (0,66 €/kg) permettant des mises en épargne avec ce système.\n\n' +
        'Ce sera donc à chaque coopérative proposant ce service d\'apporter la communication adaptée à ses adhérents.\n',
    },
    header: {
      serenity: 'La sérénité face à la volatilité des marchés',
      simpleAndInnovating: 'offre une solution simple et innovante',
      smoothIncomes: 'Une solution simple de lissage des revenus face aux aléas du marché en agriculture',
      smoothIncomesSaving: 'Lissez vos revenus face aux aléas du marché agricole',
      seeVideo: 'Protélis en vidéo',
      contactMe: 'Je veux être contacté',
      savingSolution: `Découvrez la solution d'épargne de précaution portée par les coopératives agricoles pour leurs adhérents`
    },
    part1: {
      protelis: 'Protélis en quelques mots',
      protelisComplement: 'Protélis est la déclinaison d\'un principe simple : mettre de côté les bonnes années afin de se constituer une épargne pour les années difficiles.',
      protelisAllowsTo: 'Protélis vous permet de',
      smoothIncomes: 'Lisser et sécuriser vos revenus',
      buildSaving: 'Constituer votre épargne de précaution',
      accessAdvantages: 'Accéder à une solution éligible à la Déduction pour Epargne de Précaution (DEP). Cette DEP vous permet de prétendre à des avantages fiscaux et sociaux',
      woreByCoop: 'L\'offre Protélis est portée par votre coopérative',
      coopAccompany: 'Votre coopérative vous garantit un accompagnement technique jusqu\'à la mise en place du contrat Protélis et l\'ouverture de votre compte individuel.',
      counselorHelp: 'Votre conseiller vous informe sur l\'offre Protélis, ses avantages et comment y souscrire.\n',
    },
    part2: {
      howDoesItWork: 'Comment ça fonctionne ?',
      protelisAdapted: 'PROTÉLIS adaptée à chaque production',
      priceGraph: 'Courbe des prix',
      marketPrice: 'Prix du marché',
      annualAverage: 'Moyenne annuelle de la production',
      yourPrice: 'Moyenne historique de la production',
      priceSup: 'La moyenne annuelle de la production est supérieure à la moyenne historique.\n',
      priceInf: 'La moyenne annuelle de la production est inférieure à la moyenne historique.\n',
      precaution: ' tout ou partie de la différence sur un compte individuel inscrit au sein de ma coopérative.\nCette épargne me permet de satisfaire aux conditions de la Déduction pour Epargne de Précaution et ainsi prétendre à une déduction fiscale et sociale sur l\'exercice clos.',
      smoothIncomes: ' la différence et la réintègre à mon résultat fiscal.\nJe lisse mes revenus agricoles.',
      newText: 'Je peux récupérer tout ou partie de mon épargne.\n Cette réintégration permet le lissage du prix en compensant certains « bas de cycle ». ',
      protelisSave: 'Protélis me permet de lisser et sécuriser mes revenus',
      bold: {
        create: 'Je peux épargner',
        get: 'je récupère au minimum',
      },
    },
    part3: {
      whatProcedure: 'Quelle démarche suivre ?',
      relation: 'Mise en relation',
      aboutProtelis: ' au sujet de Protélis',
      fileStudies: 'Présentation de la solution',
      withMyCounselor: ' avec mon conseiller',
      inscription: 'Inscription',
      protelisService: ' au service Protélis',
      saving: 'Épargne',
      iCan: 'Je peux ',
      choices: ' dans les bonnes années ou j\'utilise mon épargne dans les mauvaises années',
      contactMe: 'Je veux être contacté par ma coopérative',
      bold: {
        subscribe: 'Je souscris',
        studySituation: 'J\'étudie la solution Protélis',
        contactCoop: 'Je contacte ma coopérative',
        save: 'épargner',
      }
    },
    square: {
      title: 'offre disponible sur les productions suivantes',
      selectProd: 'Sélectionner votre production afin d\'être contacté par votre coopérative.',
      productions: {
        BEETS: 'Betteraves sucrières',
        BLE: 'Blé',
        RAPE: 'Colza',
        MILK: 'Lait de vache',
        CORN: 'Maïs grain',
        POTATOE: 'Pomme de terre',
        PORK: 'Porc',
        BARLEY: 'Orge',
        BLE_HARD: 'Blé dur',
        FOIE_GRAS: 'Foie gras',
        MILK_GOAT: 'Lait de chèvre',
        EGG: 'Œuf',
        SOY: 'Soja',
        COW_MEAT: 'Viande bovine',
        CARROT: 'Carottes',
        APPLE: 'Pomme',
        SUNFLOWER: 'Tournesol',
        MUTTON: 'Viande ovine',
        POULTRY: 'Viande de volaille'
      },
      contact: `Contacter notre service à l'adresse %{mail}`,
      question: 'Une question ?',
      actions: {
        select: 'Sélectionner',
      }
    },
    separator: {
      pros: 'Les',
      protelisPros: 'de Protelis',
      guarantee: 'Epargne gérée par votre coopérative',
      savingAlwaysPossible: 'Epargne toujours disponible',
      lissIncomes: 'Lissez vos revenus',
      makeDefiscSavings: 'Constituez votre épargne de précaution défiscalisée',
      manageSavingsOnline: 'Gérez votre épargne en ligne'
    },
    footer: {
      contactUs: 'Nous contacter',
      legalMention: 'Mentions légales - Politique de confidentialité',
      ourHistory: 'Qui sommes-nous ?',
    },
    prodForm: {
      actions: {
        previous: 'Précédent',
        next: 'Suivant',
        send: 'Envoyer',
      },
      step1: {
        title: 'Pour retrouver votre coopérative, sélectionnez votre région',
        placeholder: 'Votre région',
      },
      step2: {
        title: 'Séléctionnez votre coopérative',
        otherCooperative: 'Entrez le nom de votre coopérative',
      },
      step3: {
        title: 'Sélectionnez votre ou vos productions'
      },
      step4: {
        title: 'Renseignez vos informations personnelles afin d\'être contacté par votre coopérative',
        structureType: 'Type de structure',
        structureName: 'Nom de la structure',
        lastName: 'Nom',
        firstName: 'Prénom',
        email: 'Email',
        city: 'Commune',
        phone: 'Téléphone mobile',
      },
    },
  },
  password: {
    title: 'Initialisation du mot de passe',
    initialisePassword: 'Choisissez un mot de passe',
    confirmedPassword: 'Confirmation de mot de passe',
    errorLinkNotvalidOrExpired: 'Ce lien n\'est pas valide ou a expiré',
    errorLinkExpired: 'Le lien a expiré',
    errorPasswordTooShort: 'Votre mot de passe doit faire 8 caractères au minimum',
    errorConfirmationPasswordInvalide: 'Le mot de passe et la confirmation doivent être identique',
    resetPasswordButton: 'Confirmer',
    passwordSetUp: 'Votre mot de passe a bien été enregistré',
  },
  email: {
    title: 'Modification de votre adresse email',
    subtitle: 'Votre adresse email a bien été modifiée.',
    confirmationMessageBegin: 'Vous devrez dorénavant utiliser l\'adresse',
    confirmationMessageEnd: 'lors de vos prochaines connexions à votre espace Protélis.',
    invalidLink: 'Ce lien est invalide : il a peut-être expiré. \n Veuillez recommencer la procédure de renouvellement d\'adresse email.'
  }
};

export default fr;
