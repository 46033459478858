// @flow

import React from 'react';

export const RegionJSON = [
    {
        value: '1',
        label: 'Alsace',
        path: 'M540.3,215.4c4.4-4.6,13.2-22.6,13.4-27.6c0.4-8-11-10.8-7.6-15.4\n' +
            '      c2.3-3.2,7.8-2.7,9.1-5.9c1.8-4.1,1.5-8.9,0.8-12.5c-0.8-4.4-10.9-1-13.8-7.1\n' +
            '      c-2-4.4,3-10.7,7.6-9.9c5.2,0.9,5,6.3,13.8,5.3c7.2-0.8,5.6-3.6,9.4-7.6\n' +
            '      c2.7,1.7,7,2.4,11.8,2.3c3.9,0,6.4,1.8,7.2,3.4c0.4,0.7,1.5,3.1-0.5,9.4\n' +
            '      c-1,2.9-2.9,6.6-4.3,9.3c-0.2,0.3-3.9,7.6-6.7,14.4c-1.8,4.3-4,11.8-4.9,16.7\n' +
            '      c-0.9,4.9-0.2,8.6-3.3,17.8c-0.3,1-1.5,4.2-2.4,9.5c-0.7,4-0.3,8.3,2.3,11.6\n' +
            '      c1,1.2,2.7,2.9,2.5,4.3c-0.2,1.4-4.7,3.1-6.7,4.6c-0.8,0.6-6.9,3.1-13.8-0.3\n' +
            '      c-2.1-1.7-4.1-4.2-6.1-6.9c-1.1-1.6,6.1-5,5.2-6.6C550.1,218.5,539.7,215.2,540.3,215.4z'
    },
    {
        value: '2',
        label: 'Aquitaine',
        path: 'M179.2,536.8c-6.1-2.9-11.8-6.1-15-9.7c-1.2,1.7-4.1,4.2-5.2,3.9c-1.3-0.3-0.5-4.3-0.4-5\n' +
            '      c0.7-4.9-0.2-7-1.5-7.8c-1.6-1-3.9-0.8-4.9-0.9c-3.8-0.3-6.6-3.6-7.6-5.9c2-0.5,7.1-3.6,10.3-6.8\n' +
            '      c1.7-1.7,2.2-3.9,2.5-5.7c0.3-2,0.1-3.1,1.3-5.3c1-2,2-3.2,2.8-5.1c1.6-4,2.7-10.8,3.5-15\n' +
            '      c0.5-2.3,1.2-6.2,1.8-9.5c0.9-4.9,1.8-9.7,2.4-13.2c1.1-6.4,1.2-7.7,1.7-11.5\n' +
            '      c0.2-1.5,1.4-4.3,2.1-5.3c1.9-2.6,7.4-3.7,7.9-4.2c1.1-1,1.2-3.1,0.6-4.9c-1.4-4.4-5.7-4.6-9.5-3.3\n' +
            '      c2.3-7.8,3-8.4,5-11.4c1.6-3.9,2-10.7,2-14.2c0.1-9.1,0.6-20.6-1.7-24.2c9.5-1.7,15.4,10,16.9,14\n' +
            '      c5.4,14.6,8.8,18.9,10.2,17.8c-1.2-5.7-3.5-15.9-5.2-19.4c10.9-2.2,13.4,7,14.6,8.9\n' +
            '      c1.4,2,5.7,6.5,7.9,5.5c6.6-3,12.9-6.1,18.5-9c2.3-1.2-0.1-9.3,2-10.4c2-1,3.1-2.6,4.6-3.6\n' +
            '      c2.6-1.7,5.2-2.6,7.5-3.7c1.5-0.7-0.2-4.6,1.2-7c1.7-2.9,6.6-4.4,7.8-3.8c0.7,0.3,1.9,0.7,2.6,2.7\n' +
            '      c0.9,2.4,1.3,6.4,2.2,6.8c1.5,0.7,3.2-2.1,5.4-2.5c2.2-0.4,4.8,1.6,6.1,2.6c1.5,1.1,3.9-0.2,5.1,1.1\n' +
            '      c3.9,4.2,5,21.5,4.8,25.8c3,2.5,6.2,3.9,8,5.5c2,1.8,2.2,3.7,2.1,3.8c-2,2.2-3.7,5.9-5.9,9\n' +
            '      c-4.3,6.1-9.3,11.5-10.2,13.5c-1.2,2.4-6.2,1.8-7,3.8c-1.6,3.9-0.5,7.7-0.5,8.6\n' +
            '      c-0.1,2.1-5.2,3.3-5.6,6.3c-0.4,2.9,3.2,10,1.1,12.5c-3.6,4.4-21.4,5.2-26,7.1\n' +
            '      c-1.4,0.6-1.7,2.5-3.2,2.7c-6.5,0.7-10.3,2.7-12.1,4.4c-2.2,2.2-12.3-4.5-13.7-0.9\n' +
            '      c-3.2,7.9,0.8,21,0.5,23.9c4,2.4,8.5,6.2,9.6,11.8c1.3,6-5.2,18.5-10.2,21.6\n' +
            '      c-3.8,2.3-6.6,11.8-6,16.4c-1.4,0.5-2.4,0.7-3.9,0.8c-2,0.1-4.6-0.3-6.9-1c-1.5-0.5-2.8-3.6-3.3-5\n' +
            '      c-0.6-2-2.8-3-4.2-3C186.8,539.5,180,537.2,179.2,536.8z',
    },
    {
        value: '3',
        label: 'Auvergne',
        path: 'M405.9,308.9c-1.1-2.2-1.6-5.4-2.4-7.8c-0.5-1.4-1.2-2.6-2-2.8c-1.9-0.6-4-0.3-6.2-0.2\n' +
            '      c-2.6,0-5.2-0.1-7.5-0.3c-5.1-0.4-9-1.7-12-3.3c-2.1-1.1-3.4-2.2-4.2-2.8c-1.5,1.9-2.7,2.7-4.3,3.4\n' +
            '      c-2.5,1-10.2-0.2-12,1.9c-1.3,1.6-0.4,5.3-0.6,7.5c-0.2,2.5-0.5,2.5-0.8,2.9c-1.5,3.1-9.9,2-11.3,5\n' +
            '      c-0.8,1.6-1.4,4.6-1.9,6.3c-0.4,1.4-0.6,1.6-1.2,2.2c3.2,3.9,10.4,5.1,12.5,9\n' +
            '      c2,3.6,0.6,8.5,1.1,10.8c2.9,13.1-7.2,8.9-7.8,15.3c-0.8,8.3,6.7,19.8,6.5,24.4\n' +
            '      c-0.2,3-10,4.5-13.9,8.2c-5.2,4.8-4.8,12.7-4.8,15.2c0,3.2-2.4,6.2-3.7,7.9c0.4,2.2,0.9,4.3,1.4,6\n' +
            '      c0.9,3.1,1.2,7.4,1.4,9.5c0.4,4.2,0.4,4.8,1.9,5.4c0.8,0.3,1.8-1,2.9-2c0.8-0.7,1.6-1.2,2.1-1.3\n' +
            '      c1-0.3,2.6-0.8,4.2-1.1c1.8-0.3,3.6-0.3,4.7-0.9c7-3.5,6.9-14.7,9.8-14.4c3.8,0.4,6.8,4.7,8.1,6.8\n' +
            '      c3.6,5.8,4.2,9.2,4.8,10.5c0.6-2.5,2.1-5.6,2.9-6.8c1.6-2.4,3.6-5.4,6.1-6.5\n' +
            '      c0.4-0.2,1.1-0.3,1.5-0.4c0.7-0.2,1.3-0.5,1.9-0.7c1.3-0.4,2.5-1,3.7-1.3c0.5-0.1,1-0.1,1.4-0.1\n' +
            '      c1.7-0.2,3.5,2.6,5.5,4.5c1.1,1.1,2.2,1.5,2.7,1.4c0.6-0.1,1.7-1.7,2.5-2.7c0.4-0.5,0.9-0.7,1-0.7\n' +
            '      c1.1-0.2,4.7,2.9,6.1,3.8c4.7,3,6.9,4,9.2,5.5c-0.2-1.1-0.5-3.2-0.5-3.7c0.9-10,17-5.7,16-15.5\n' +
            '      c-0.6-5.5,15.7-13.4,10.9-13.6c-3.1-0.1-5.8-0.2-8-0.3c-3-0.2,3.3-9.5,0.5-9.4\n' +
            '      c-3.5,0.1-6.8,0.4-9.7,1c-10.7,2.4-12.2,0.1-10.6-9.3c1.6-10-9.2-15.2-10-17.9\n' +
            '      c-3-9.8,2.2-9.2,3.2-15.9c0.7-4.6-2.1-10.8-1.3-12.4c0.7-1.4,4.7-4.6,7.6-4.6\n' +
            '      c0.3-0.8,0.5-1.6,0.9-2.9c0.3-0.9,0.4-2.5,0.1-3.2\n' +
            '      c-0.6-1.6-2.9-3.3-4.8-5C408.2,311.9,406.8,310.5,405.9,308.9z',
    },
    {
        value: '4',
        label: 'Basse-Normandie',
        path: 'M272.7,169.4c-1.4-3.2,3.6-6.7,2.3-7.6c-2.9-2.2-6.3-4.7-9.4-6.6c-2.8-1.7-5.1-0.8-6.9-2\n' +
            '      c-4.5-3-2.3-20.9-6-25c-2.6-2.8,0.2-7.7-2.1-10.1c-1.1-1.1-2.3-1.2-3.4-1.9\n' +
            '      c-5.2,5-11.6,9.9-16.2,11.4c-7.4,2.5-20.4-5.9-24.6-7.4c-2.9-1-8.1-2.2-11-0.7\n' +
            '      c-4.5,2.3-4.6,7.2-6.1,6.9c-2.5-0.5-5.4-13.1-6-15.2c-0.7-2.6,4.4-4.6,4.4-7.6\n' +
            '      c0-2-3.1-5.7-5.1-5.5c-1.6,0.2-7.2,2.8-8.2,3.3c-2.3,1.3-7.3-0.4-9.2-1.6c-3.5-2.2-9.4-6.7-9.6-6.2\n' +
            '      c-0.3,0.8,3.4,6.8,5.3,9.4c-0.3,4.5,1.5,11.1,3.5,15.1c1.2,2.8,3.2,4.8,4.7,7.3\n' +
            '      c1.9,3.2,2.2,5.4,2.6,8.1c0.8,5.2-0.1,10.6-0.4,12c-2.3,2.9-3,6.9-1.5,9.6c2.2,4.2,6.7,7.6,8.9,8.6\n' +
            '      c-3.1,1.9-7.1,4.7-9.6,6c-0.2,3.7-0.5,7.1,1.3,8.3c1.3,0.9,7.6-2.6,9.3-3.5c3.5-1.9,6-2.1,7.9-1.9\n' +
            '      c2.9,0.3,4.1,3.3,4.6,3.9c3.2-2.3,18.7-0.9,27.3-1.3c4.9-0.2,2.7-3.5,8.3-3.4\n' +
            '      c4.7,0.1,7,11.3,11.6,11.5c3.1,0.1,5.5-3.3,8.5-3.7c5.7-0.6,5.3,16.4,15.6,13.9\n' +
            '      c3.9-0.9,7-0.8,9.2,0.5c-0.3-2.5-0.6-4.8,0-7\n' +
            '      c0.9-3.1,6.2-4.4,5.9-8.2C278.4,175.3,273.7,171.5,272.7,169.4z',
    },
    {
        value: '5',
        label: 'Bourgogne',
        path: 'M371,291.9c0.3-2.3,0.6-4.7,0.5-7.2c-0.1-6.5-2.9-13.6-4.7-18.8\n' +
            '      c-3.8-11.3-2.3-27.2-3.1-33.8c-0.6-5.8,12.4-15,11.3-19.7c-1.3-5.6-5.1-10-6.3-11\n' +
            '      c0.4,0,1.4-0.1,1.7-1.7c0.6-2.7,0.1-7.3,2.4-9.4c0.6-0.5,1-1,1.7-1.3c1.7-0.7,4.1-0.7,6.6-1.2\n' +
            '      c2.5-0.5,6.1-1.2,8.1-2.7c0.3,0.6,0.8,1.4,1.3,2.2c0.5,0.8,1,1.5,1.3,2.6c2,7.8,2.7,11.1,4.8,12.6\n' +
            '      c1.8,1.3,6.1,2.9,7.3,4.4c2.7,3.4,5.3,10.7,7.8,11.3c0.7,0.2,1.4,0.7,2.2,0.1c2.1-1.6,4.2-3,5.9-3.1\n' +
            '      c2.2-0.2,6.4,0.2,9.6-0.3c1-0.1,2.1-0.8,3.1-1c1.8-0.3,3.6,2.1,5.1,2.2c2.9,0.2,5.9-7.1,7.9-7.1\n' +
            '      c2.2,0,5.2,3.2,7.5,8.1c1.6,3.3,2.6,7.4,3.7,11.5c0.3,0.9,0.7,1.6,1,2c0.4,0.6,1-0.4,2.2,0.1\n' +
            '      c3.9,1.6,6.9,7.5,10.7,9.7c1,0.6,2.6-1.3,3.8-4c1.3,1,4.5,5.1,3.4,7.7c-0.3,0.7-0.6,1.6-0.6,2.5\n' +
            '      c0.2,1.7,1.6,3.4,2.6,5.4c0.6,1,1.5,2.5,1.4,4.1c-0.1,2.2-3.6,7.1-4.7,9.3c-0.9,1.7-6.2,10-4.8,13.4\n' +
            '      c0.3,0.7,0.6,1.7,1,2.4c1.6,2.8,4.3,5.3,6.6,7.3c1.6,1.4,0,6.3-0.8,8.9c-0.8,2.5-4.5,10.6-4.3,15\n' +
            '      c-1-0.4-3.2-1.4-5.4-2.3c-1.7-0.6-5.7-3.5-6.8-2.3c-1.6,1.7-4.8,4.2-5.1,4.9c-0.6,1.4-0.8,2.8-1,4.8\n' +
            '      c-0.1,1.2-0.2,3-0.4,4.5c-0.1,1-0.3,2-0.4,2.9c-0.1,0.9-0.4,1.3-0.7,1.9c-0.2,0.4-0.9,1.4-1.6,1.9\n' +
            '      c-0.6,0.5-1.2,0.1-1.6-0.4c-0.1-0.1-0.5-1,0-2.4c0.2-0.6,0.3-1.1,0.4-1.6c0.1-0.4,0.2-0.8,0-1.2\n' +
            '      c-0.2-0.4-0.7-0.1-1.2-0.2c-1.5-0.2-3.4-0.8-4.1-1c-1.2-0.3-2.5-0.6-4.3-0.8c-0.4,0-2.8-0.2-4.1,1.2\n' +
            '      c-0.8,0.9-1.3,2.2-1.8,3.7c-0.4,1-0.8,2.3-1.3,3.3c-0.4,0.8-1.4,1.5-2.1,2.1c-1,0.8-4,2.8-6.2,3.3\n' +
            '      c-1,0.2-2.2,0.6-3.3,0.5c-1.9-0.1-3.1-1-4.4-3c-1-1.5-1.8-3.2-2.9-4.9c-0.7-1.1-1.9-2.2-3.4-2.3\n' +
            '      c0.2-1.4,1.2-2.6,1.1-5.3c0-2.8-5.8-5.2-8.6-10c-2.5-4.2-1.7-11.1-5.8-11.2\n' +
            '      c-3.4,0-11.6,0.4-18.2-1.2C375.2,295.7,370.6,292.2,371,291.9z',
    },
    {
        value: '6',
        label: 'Bretagne',
        path: 'M154.8,161c1,2.2,0.2,4.7,0.4,6.8c-1,0.8-10.8-0.2-16.5-0.4c-0.6-2.8-2.3-4.1-4.5-4.2\n' +
            '      c-4.5,0-12.3,6.2-15.8,5.7c-2.2-0.3-3.7-2.9-5.8-6.1c-1.7-2.6-3.9-5.6-6.4-7.8\n' +
            '      c-3.7-3.5-10.1-4-12.9-4.3c-4.1-0.4-7.5-0.2-8.9,0.1c-7.6,7.5-9.9,8.6-12.5,8.4\n' +
            '      c-2.3-0.1-5.1-4.6-7.1-4.8c-2.1-0.2-9.6,2.5-11.1,2.6c-6.9,0.5-10.3,1.2-14.1,3.1\n' +
            '      c-3.7,1.8-6.5,4.3-8.3,6.4c-2.2,2.5-1.7,6.1,0,7.8c0.6,0.6,2.1,1.1,4,0.6c2.5-0.6,4.8-2.6,8.2-2.5\n' +
            '      c3.1,0.1,5.1,1.9,5.6,2.6c1,1.4,1,3.3,0.9,4.1c-0.2,2.7-2.7,3.1-5.6,2.7c-2.1-0.3-4.5-1-6.3-1.6\n' +
            '      c-1.5-0.5-2.6-0.5-3.2-0.6c-1.1-0.3-3,4.7-2.1,6.4c0.7,1.3,6.9,0,11.2,0.7c1.1,0.2,2.4,0.7,3.1,1.3\n' +
            '      c0.8,0.7,1,1.7,1.1,2c0.5,1.2-0.5,5.9-3.1,6.2c-1.5,0.2-4.2-1.3-6.5-2.3c-2.1-0.8-3.8-1.1-4.5-0.9\n' +
            '      c-0.9,0.3-0.4,1.4-0.2,1.9c3.8,6.5,9.8,17,12.6,17c2.9,0,5.8-4.5,7.6-8.4c1.1,1.5,2.5,3.4,3.9,3.6\n' +
            '      c2.2,0.3,6-2.3,7.6-1.9c3,0.7,2.7,4.2,5.1,9.1c1,2,4,1.5,5.2,1.6c1.3,0.1,2.7,2.3,4.2,3.8\n' +
            '      c1.1,1,2.4,1.4,3.3,1.5c3.5,0.3,8.7-4.2,11.7-2.9c3,1.3-2.1,11.4-2.5,14.5c-0.4,3.2,2.1,9.4,4.8,8.8\n' +
            '      c1.6-0.3-1.9-3,4.3-11.1c0.9-1.2,5.9-4.7,9.7-2.9c8.6,4.2,15.1,7.3,17.6,9c-0.9,1.8-1.5,4-2,5.4\n' +
            '      c2,0.2,5.9-0.5,10.6-4.6c2.6-2.2,6.7-5.4,9.6-8.3c2.7-2.8,11.7,0.8,14.3-2.3c1.6-2,3.7-6.9,6.8-7.7\n' +
            '      c5.2-1.4,11.7,1.8,12.9-2.1c0.4-1.5,0.5-5.7,2.6-7.6c2.9-2.6,8-3,7.3-5.5\n' +
            '      c-2.5-7.9-5.8-22.1,0.6-26.7c-1.3-1.9-2.2-4.6-7.6-3.9c-4.3,0.6-10,5.6-13.8,5.4\n' +
            '      c-2.5-0.1-1.9-5.5-1.7-8.4c-1.1,0.7-2.4,0.6-3.6,0.4C161.4,170,155.8,162.4,154.8,161z',
    },
    {
        value: '7',
        label: 'Centre',
        path: 'M270.3,284.8c1.3,1.1,3.4,8.2,3.9,10.7c1.2,5.1-0.7,8,1.2,9.2\n' +
            '      c7.5,4.9,12.8,10.6,11.9,14.1c2.4,0.9,4.9,1.6,7.5,2.2c2.4,0.5,4-0.9,6.8-1.2\n' +
            '      c2.6-0.4,6.5,0.3,8.8,0.2c3.5-0.1,5.1-3.8,8.3-4.4c4.6-1,10.6,0.9,13.6,1.7c3.3,0.8,5.6,3.3,6.6,3.9\n' +
            '      c1.9-1.3,1.8-7.9,3.8-9.1c3.1-2,7.8-1.5,10.1-3.7c2.5-2.4,0.4-9.3,1.8-10.9\n' +
            '      c1.8-2.1,7.5-1.1,11.4-1.9c3-0.6,4.3-3.1,5.1-3.7c0.3-2.3,0.6-4.7,0.5-7.2\n' +
            '      c-0.1-6.5-2.9-13.6-4.7-18.8c-3.8-11.3-2.3-27.2-3.1-33.8c-0.6-5.8,12.4-15,11.3-19.7\n' +
            '      c-1.3-5.6-5.1-10-6.3-11c-3.9,0.5-7.4-1.1-8.9-0.9c-3.3,0.4-6.3,4.2-9.6,3.5\n' +
            '      c-2-0.5-2.6-2.7-2.9-5.1c-0.4-3.1-0.6-6.6-5-7.7c-5.5-1.2-12,3.5-16.2,2.2\n' +
            '      c-4.8-1.4-4.2-4.3-7.2-9.9c-0.9-1.6-6.8-3.4-7.9-4.3c-3.2-2.5-4.3-11.6-5-14.6\n' +
            '      c-0.9-4-1.4-6.8-2.9-7.8c-1.6-0.7-3.2-1.2-4.8-1.4c-1.1,1.3-2.6,2.8-4.1,4c-6.6,5-8.1,5.2-11.3,5.7\n' +
            '      c-3.4,0.5-6.8-1.7-8.2-2.7c0.1,0.6-2.7,4-2.8,5.7c-0.2,2.5,3,5,4.1,6.8c1.2,2,2.7,4.2,1.8,6.2\n' +
            '      c-1.1,2.7-4.9,4.6-5.7,6.7c-0.9,2.3,0,6.3,0,7c2.2,1.3,3.5,3.8,3.7,7.4c0.2,3.2-4.3,0.9-4.9,4.1\n' +
            '      c-0.6,3,0.4,7-0.4,10.1c-0.7,2.7-3.9,5.5-6.9,8.7c-2.3,2.5-4.1,5.4-7,8.1\n' +
            '      c-3.2,3.1-20.9,2.1-18.8,7.3c2.6,6.6-0.4,13.7-0.9,18.8c-0.5,4.9-0.5,4.9-0.2,7.5\n' +
            '      c-0.8-0.2,2,0,5.4,2.9c3.7,3.2,5.2,8.4,9.9,10.9C256.7,283,267,282,270.3,284.8z',
    },
    {
        value: '8',
        label: 'Champagne-Ardenne',
        path: 'M387.2,163.5c-2.4-5.5-1.1-5.7,5.7-8.7c2.6-1.1,4.8-3.4,6.7-6.5c1.1-1.8-4.4-6.8-3.5-9.1\n' +
            '      c0.7-1.9,3.1,0,3.8-2c1.3-3.8-3.4-6.2-2.4-10.4c1.6-6.5,15.3-1.7,17.5-7.6c3.3-8.9-4.2-13.9,0.1-17\n' +
            '      c2.5-1.8,5-1.7,6.6-5c1.7-3.6-0.3-9.8,6.9-13.6c1.2,0.8,3.6,1.5,5.9-0.5c2.1-1.9,2.6-3.2,5.8-8.9\n' +
            '      c1.6-2.8,3.8-3.9,5.4-2.5c2.7,2.2-3,9.8-1.2,15.3c1.2,3.7,5.1,5.2,14.5,9.6\n' +
            '      c2.6,1.3,10.1,6.8,12.1,8.4c-0.5,0.6-2.2,2.5-4.3,3.1c-1.5,0.4-4.2-2.5-6-2.2\n' +
            '      c-1.2,0.2-2.4,2.2-3.5,5.1c-0.8,2.1,3.5,6.7,2.7,9.3c-0.7,2.4-2.8,3.6-4.3,5.6c-1,1.3,2,14.3,0,19.2\n' +
            '      c-0.6,1.5-2,1.7-2.9,3c-1.7,2.5,3.1,19,7.4,23.3c1,1,8,3.5,13.8,9.5c1.8,1.9,1.2,5.7,3.1,7.9\n' +
            '      c1.8,2.1,6.6,0,8.5,2.1c3.6,4,1.5,15.2,4.3,19.3c1.8,2.6,4.2,1,6.1,2.6c-1.9,2.5-4.1,5.1-6.3,7.5\n' +
            '      c-1.3,1.4,3.6,9.9,2.3,11.1c-3.8,3.6-12.9,1-13.8,1c-2.4,0-2.1,4.3-5.8,7.6\n' +
            '      c-3,2.7-8.9-11.2-13.5-9.3c-1.7,0.7-2.3-6.8-4.8-12.6c-2.7-6.1-7.2-10.7-9-8.7\n' +
            '      c-1,1.1-2.6,3.8-4.8,5.8c-3.3,3-4.6-3.1-8.5-0.8c-2.1,1.2-7.5,0.2-11.9,0.8\n' +
            '      c-4.2,0.6-4.6,5.1-8.4,2.5c-2.7-1.9-4.1-7.6-7.1-11.1c-2-2.4-6-2.5-8-5.1c-2.9-3.7-2.4-9.2-4.6-13.3\n' +
            '      c-0.5-1-1.2-2-1.6-3c-0.8-2,4.3-11.5,2.8-14.7C390.7,165.9,388.9,167.5,387.2,163.5z',
    },
    {
        value: '9',
        label: 'Corse',
        path: 'M560.7,552.2c0,0,10.4,0,7.3-4.4c-3-4.4-11-2.4-4.9-7.7c6.1-5.2,21.2-26.6,31.7-20.5\n' +
            '      c3.5,2.1,6-0.9,3.7-10.5c-2.3-9.6,6.1-7.9,5.8-4.3c-0.2,3.7,0,19.7,0,19.7s4.4,1.1,2.8,12.4\n' +
            '      c-1.6,11.3,6.1,8-0.4,20.4c-6.5,12.3-4.5,22.5-4.6,26c-0.1,3.4-5.9,4.5-6.2,11.7\n' +
            '      c-0.4,7.2-4.5,19.6-6.1,13c-1.6-6.6-2-11.3-7.1-10c-5.1,1.3-15.7,0.2-5-10.8c0,0-9.8-6.9-6.7-15\n' +
            '      c0,0,1.3-1.9-2.1,0c-3.4,2-7.6-3.9-2.4-7.3\n' +
            '      c5.1-3.4-4-5.5-5-5.8C560.6,558.8,558.5,552.3,560.7,552.2z',
    },
    {
        value: '10',
        label: 'Franche-Compté',
        path: 'M539.9,215.8c-1.9-0.6-10.9-3.4-17.2-7.5c-3,1.4-7.4,3.7-11.1,3.6\n' +
            '      c-3.5-0.1-5.7-3.5-8.9-3c-2.4,0.4-6.6,2.7-7,3.2c-3.3,3.5-6.2,7.2-6.9,7.9\n' +
            '      c-1.7,1.6,4.3,10.1,2.3,11.3c-2.5,1.4-5.3,2.2-8.1,1.7c-1.4-0.3-2.7-0.1-3.6-0.5\n' +
            '      c-3.3-1.4-4.5,1.4-5.1,3.6c3.2,2.9,4.3,6.1,3.2,8.2c-2.2,4.1,5.4,8.8,3.3,12.9\n' +
            '      c-3.4,6.9-7.5,12.2-9.1,18c-1.9,6.9,8.1,12.1,8.3,14.3c0.5,5.8-6.9,18.1-5.8,22.9\n' +
            '      c1.1,4.8,4.7,6.8,6.3,9.9c1.8,3.4,6.9-8.3,8.9-5.5c2,2.8,4.5,3,6.5,4.8c3.3,3.1,10-9.5,14-7.8\n' +
            '      c-0.2-1.1-1.4-5,0.4-9.5c0.7-1.6,2.8-4,4-5.3c2.7-3.2,5.9-7.3,6.8-8.5c1.2-1.5,4.2-7,7.5-11.7\n' +
            '      c3-4.3,6.7-8.6,8.1-10.5c5.5-7.7,14.6-12.4,14.2-16.9c-0.3-2.7-3.5-4-3.3-6.2c0.4-3.7,5-6.4,6.3-7.1\n' +
            '      c-2.1-1.7-4.1-4.2-6.1-6.9c-1.1-1.6,6.1-5,5.2-6.6C549.7,218.8,539.3,215.6,539.9,215.8z',
    },
    {
        value: '11',
        label: 'Haute-Normandie',
        path: 'M274.7,162.1c-2.9-2.2-6.3-4.7-9.4-6.6c-2.8-1.7-5.1-0.8-6.9-2c-4.5-3-2.3-20.9-6-25\n' +
            '      c-2.6-2.8,0.2-7.7-2.1-10.1c-1.1-1.1-2.3-1.2-3.4-1.9c-1-0.8-3.9-2.8-3.2-6.7\n' +
            '      c0.6-3.1,3.8-5.6,7.6-8.6c1.7-1.3,6.5-6.7,10.7-8.6c2.6-1.1,8.4-2.1,12.5-2.5c5.1-0.5,12-4.1,17-7.8\n' +
            '      c1.9-1.4,5.3-5.6,6.9-7.6c0.9,0.9,2.8,2.9,5,5c1.8,1.7,3.9,3.5,6.2,5.5c0.8,0.7,1.9,1.6,3.2,2.6\n' +
            '      c0.8,0.6,2,1.2,2.5,2.2c0.4,0.8,0.3,3,0.1,3.9c-0.6,3-2,9.1-2.1,11c0,1-0.3,2.6-0.1,3.5\n' +
            '      c0.2,0.7,1.1,0.8,1.3,1.6c0.3,1,0.6,2,0.7,3.2c0.1,2.1,0.4,6.5,0.5,11.1c0,2.3,0.2,4.6,0.5,6.6\n' +
            '      c-5.7,1.7-10.6,8.8-12,15.5c-0.7,3.5-4.6,5.8-5.6,8.3\n' +
            '      c-0.7,1.7-8.1,8.2-13.1,9.6C279.5,166.3,275.4,162.6,274.7,162.1z',
    },
    {
        value: '12',
        label: 'Ile de France',
        path: 'M348.1,138.9c4.1,2.8,13.4,8.3,21.2,2.4c4.8-3.6,16.3,16.5,23,13.5\n' +
            '      c-2.3,1.2-5.9,2.2-6.5,3.8c-0.8,2,0.7,4.3,1.1,5.6c0.6,1.8,3.3,3.2,4.2,4.4c1.4,1.9,1.6,3.3,1.3,4.5\n' +
            '      c-1,4.9-3.1,9.7-3.1,11.9c-4.3,3.2-13.5,3.1-15.1,4.1c-5.6,3.5-2,11.1-5,12.1c-3,1-7.7-0.8-9.2-0.8\n' +
            '      c-3.8,0.2-7.2,4.9-10.2,3.4c-4.6-2.4-0.5-10.7-6.9-12.5c-6.5-1.8-12.6,4.1-17.7,1.8\n' +
            '      c-5.2-2.4-3.5-8.3-7.8-11c-3.6-2.2-6.4-1.5-8.2-5.4c-3-6.6-3.1-18.8-6.3-20.1\n' +
            '      c-2-0.8-3.6-1.1-4.5-1.2c0.2-0.4,0.5-1,0.8-1.4c0.9-1.3,3.9-4.2,4.6-5.8c0.7-1.5,0.9-3.3,1.5-4.8\n' +
            '      c0.8-2.2,2-4.1,3-5.7c2.3-3.8,6.4-6,8-6.4\n' +
            '      c0.4,2.1,1.2,3.2,2.5,2.7C323.9,132,334.3,129.7,348.1,138.9z',
    },
    {
        value: '13',
        label: 'Languedoc-Roussillon',
        path: 'M429.7,507.9c-5.8-8.2-8.6-6.5-15.5-0.2c-1.1,1-3.6,3.6-6.2,6c-5.5,5.3-12.9,8-17.4,7.2\n' +
            '      c-2.1,1.4-5.9,4.3-11.7,9.8c-2,1.9-3.2,3.3-4.4,6.1c-1,2.3-0.8,3.7,0.6,6.3c1,1.9,1.7,4.9,1.3,9.6\n' +
            '      c-0.2,3.1,1.3,7.8,2.4,11.4c0.5,1.5,2.5,8,2.2,10.9c-0.1,1.3-0.6,2.8-2.1,2.4\n' +
            '      c-2.5-0.5-4-2.6-6.8-3.6c-2.2-0.8-12.1,5.7-14.5,7.2c-1.1,0.7-3.6,2.4-13.5-2.2\n' +
            '      c-2.5-1.1-11.6-0.3-14.2,0.4c-2.6,0.7-9.3,1.5-12.5-3.2c-1.5-2.2-3.4-6.1-4.8-8.3\n' +
            '      c3.2-0.8,18.7-1.2,19.7-5.9c0.7-3.4-9.4-4-10.8-8.5c-3.1-9.8,1.3-11,0.4-17\n' +
            '      c-0.8-5.2-11.5-10.3-12.6-15c-0.5-2,1-5.9,3.7-9c3.6-4,36.9,3.6,40.9-2.6c1.1-1.6-0.3-4.1,0.2-6\n' +
            '      c1.1-3.9,15.1-6.5,17.9-12.8c0.5-1.2,6.2-0.5,8.5-3.3c4.4-5.3,11.3-11.9,10.9-14.8\n' +
            '      c-0.7-5.4-7.3-7.6-10.4-12.8c-2.2-3.6-0.5-10.8-3-14.3c-2.5-3.4-8.5-8.9-7.9-15.1\n' +
            '      c0.4-3.7,5.7-13.2,10-13.8c2.2-0.3,5.3-2.6,8-2.1c2.6,0.4,4.4,4.9,7.2,5.9c1.7,0.6,3-4.1,4.8-3.4\n' +
            '      c1.7,0.7,4.7,3.3,6.4,4.4c3.4,2.1,5.4,2.9,8.3,4.9c0.3,2.2,3.5,13.3,5.3,19c1.2,3.9,1.4,9.1,8.2,9.5\n' +
            '      c1.6,0.1,9.6-1.8,15.8-2.1c1.7-0.1,3.2,0.6,4.4,0.9c0.9,0.2,1.8,0.2,2.2,0.3\n' +
            '      c-1.1-0.1-1.1,11.3,9.8,22.3c-6.1,0.6-14.3,4.9-14.6,15C435.7,499.8,431.3,505.6,429.7,507.9z',
    },
    {
        value: '14',
        label: 'Limousin',
        path: 'M338.5,320.8c3.2,3.9,10.4,5.1,12.5,9c2,3.6,0.6,8.5,1.1,10.8c2.9,13.1-7.2,8.9-7.8,15.3\n' +
            '      c-0.8,8.3,6.7,19.8,6.5,24.4c-0.2,3-10,4.5-13.9,8.2c-5.2,4.8-4.8,12.7-4.8,15.2\n' +
            '      c0,3.2-2.4,6.2-3.7,7.9c-0.7-0.2-3-0.4-5.8-0.5c-3.8-0.1-8.2,0.2-9.5-0.5c-2-1-4.1-2.4-6.2-3.6\n' +
            '      c-1.9-1.1-4-2.6-7.3,0.5c0.1-1.5-1.5-4-6.5-6.6c-1.2-0.7-2.4-1.7-3.6-2.6c0-1.3,0-6.7-0.6-11\n' +
            '      c-0.5-3.4-1.1-9.4-3.5-14c-0.2-0.3-0.6-1.1-1.5-1.3c-1-0.3-2.4-0.1-3.6-0.3c-0.7-0.1-1.2-0.8-2-1.3\n' +
            '      c-1.1-0.8-2.4-1.5-3.6-1.6c-0.8-0.1-1.8,0.1-2.8,0.8c-1.2,0.7-2.3,1.8-3.3,1.9\n' +
            '      c-1.3,0-1.6-3.7-2.4-6.4c-0.5-1.7-1.9-3.1-2.8-3.2c0.4-2,0.7-5.7,5.5-8.6c1.3-0.8,4.5-2.5,5.9-5.3\n' +
            '      c1-2.2-0.4-4.9-2.1-7.9c-1.6-2.9-3.8-5.7-3.1-8.2c0.5-1.9,2.6-2.8,4.9-3.9c1.5-0.7,3.1-1.3,4.6-2.2\n' +
            '      c2.5-1.5,4.7-3.3,6-4.8c0.3-0.4,0.7-0.7,1.1-1.2c0.3-0.3,0.6-0.8,0.9-1.3c1.6,0.5,5,1.7,8.3,2.3\n' +
            '      c1.1,0.2,2.3-0.4,3.5-0.8c1.5-0.5,3.1-0.8,4.7-0.7c2.2,0.1,4.4,0.2,6.7,0.3c2.7,0.1,6.1-4.6,8.7-4.6\n' +
            '      c0.7-0.2,1.5-0.2,2.3-0.2c1.3,0,2.6,0.1,3.7,0.3c3.3,0.6,6.3,1.2,8.5,2.1\n' +
            '      c1.8,0.8,3.1,1.8,3.9,2.5C338.1,320.4,338.4,320.7,338.5,320.8z',
    },
    {
        value: '15',
        label: 'Lorraine',
        path: 'M470.8,104.6c-0.5,0.6-2.2,2.5-4.3,3.1c-1.5,0.4-4.2-2.5-6-2.2c-1.2,0.2-2.4,2.2-3.5,5.1\n' +
            '      c-0.8,2.1,3.5,6.7,2.7,9.3c-0.7,2.4-2.8,3.6-4.3,5.6c-1,1.3,2,14.3,0,19.2c-0.6,1.5-2,1.7-2.9,3\n' +
            '      c-1.7,2.5,3.1,19,7.4,23.3c1,1,8,3.5,13.8,9.5c1.8,1.9,1.2,5.7,3.1,7.9c1.8,2.1,6.6,0,8.5,2.1\n' +
            '      c3.6,4,1.5,15.2,4.3,19.3c1.8,2.6,4.2,1,6.1,2.6c1.9-1.4,4.6-3.6,8-3.7c2.8,0,5,2.7,8.3,3\n' +
            '      c3.5,0.3,8.1-2,11.2-3.6c6.4,4.4,15.8,6.9,17.2,7.5c4.4-4.6,13.2-22.6,13.4-27.6\n' +
            '      c0.4-8-11-10.8-7.6-15.4c2.3-3.2,7.8-2.7,9.1-5.9c1.8-4.1,1.5-8.9,0.8-12.5\n' +
            '      c-0.8-4.4-10.9-1-13.8-7.1c-2-4.4,3-10.7,7.6-9.9c5.2,0.9,5,6.3,13.8,5.3c7.2-0.8,5.6-3.6,9.4-7.6\n' +
            '      c-5.3-2.9-10.4-7.6-12.8-6.4c-4,2-9.4,4.5-14.6,4c-7.2-0.8-12.8-7-14.6-8.9\n' +
            '      c-6.8-7.4-10.2-12.2-15.5-13.2c-4-0.8-11.7,0.6-13.3,0.9c-5.1,1.1-9.7-2.7-12.5-3.3\n' +
            '      c-4.1-0.9-6.9-0.7-8.5,0c-0.9,0.4-1.5,1.3-2.4,1.5C476.5,109.9,473.3,106.2,470.8,104.6z',
    },
    {
        value: '16',
        label: 'Midi-Pyrenées',
        path: 'M312.4,567.8c3.2-0.8,18.7-1.2,19.7-5.9c0.7-3.4-9.4-4-10.8-8.5c-3.1-9.8,1.3-11,0.4-17\n' +
            '      c-0.8-5.2-11.5-10.3-12.6-15c-0.5-2,1-5.9,3.7-9c3.6-4,36.9,3.6,40.9-2.6c1.1-1.6-0.3-4.1,0.2-6\n' +
            '      c1.1-3.9,15.1-6.5,17.9-12.8c0.5-1.2,6.2-0.5,8.5-3.3c4.4-5.3,11.3-11.9,10.9-14.8\n' +
            '      c-0.7-5.4-7.3-7.6-10.4-12.8c-2.2-3.6-0.5-10.8-3-14.3c-2.5-3.4-8.5-8.9-7.9-15.1\n' +
            '      c0.1-0.7-5.6-16.9-12.8-17.5c-1.8-0.1-2.9,5.4-4.8,9.1c-1.9,3.6-4.6,5.5-6.4,5.7\n' +
            '      c-2.6,0.3-6.4,0.8-8.5,2c-2.1,1.2-2.6,3.2-4.3,2.8c-1.9-0.4-1.4-4.6-2-9.4c-0.5-3.9-2-8.4-2.5-11.5\n' +
            '      c-4.4-1-9.6-0.1-13.8-0.5c-3.6-0.4-8.6-5.7-11.8-5.3c-1.3,0.1-2.3,0.9-3.3,2c-2,2.2-3.7,5.9-5.9,9\n' +
            '      c-4.3,6.1-9.3,11.5-10.2,13.5c-1.2,2.4-6.2,1.8-7,3.8c-1.6,3.9-0.5,7.7-0.5,8.6\n' +
            '      c-0.1,2.1-5.2,3.3-5.6,6.3c-0.4,2.9,3.2,10,1.1,12.5c-3.6,4.4-21.4,5.2-26,7.1\n' +
            '      c-1.4,0.6-1.7,2.5-3.2,2.7c-6.5,0.7-10.3,2.7-12.1,4.4c-2.2,2.2-12.3-4.5-13.7-0.9\n' +
            '      c-3.2,7.9,0.8,21,0.5,23.9c4,2.4,8.5,6.2,9.6,11.8c1.3,6-5.2,18.5-10.2,21.6\n' +
            '      c-3.8,2.3-6.6,11.8-6,16.4c4.7-2.5,7.2-0.6,7.5-0.3c1.8,1.9,3.6,3.9,5.4,6\n' +
            '      c3.1,3.7,11.9,2.9,16.2,2.8c5.1-0.1,10.6,0.1,14.6-1.5c2-0.8,2.1-2.2,3.9-3.4\n' +
            '      c2.1-1.3,5.8-2.3,7.8-2.8c6.1-1.5,11.6,3.8,13.4,5.2\n' +
            '      c8.2,6,12.8,3.7,17.4,4.1C307.3,560,311.5,566.3,312.4,567.8z',
    },
    {
        value: '17',
        label: 'Nord-Pas-de-Calais',
        path: 'M311.6,21.9c0,0,29.5-7.8,34.1-10.1c4.6-2.3,6.6,4.3,6.6,6.9c0,2.7,1,7.6,1.7,7.8\n' +
            '      c0.7,0.2,10.6,7.4,22.1,6.9c0,0,1.6,12.6,6.2,15.2c0,0,4.5-3,6.5,0c1.9,3,6.7,10.4,15.5,10.7\n' +
            '      c8.8,0.4,17.1,1,13.9,5.2c-3.2,4.3-1.5,9,2.3,11.1c1.9,1,3.8,3.9,5.8,6\n' +
            '      c0.3,0.3-8.1,1.1-16.4-0.4C405.6,80.6,403,77,398,77c-6.7-0.1-13.3,5.4-19.1,5\n' +
            '      c-3.7-0.3-0.3-2.7-3.6-3c-7.9-0.7-12.4,2.2-14.1-0.2c-3.3-4.7-9.6-3.7-14.4-4.4\n' +
            '      c-3.5-0.5,2.1-7.6-1.3-8c-5.3-0.6-9.5,2.8-14.2,2.4c-5.8-0.5-4.1-8.9-8.6-8.3\n' +
            '      c-7.3,1-13.8-0.8-14.3-1.4c-2.3-2.9,4.8-7.6,4.6-9.9\n' +
            '      c-0.3-2.3-3.6-3.8-3.6-8.6C309.3,35.9,306.9,36.1,311.6,21.9z',
    },
    {
        value: '18',
        label: 'Pays de la Loire',
        path: 'M154.3,255.7c-0.2-0.3-0.9-1.7-2.4-2.7c-1.7-1.1-4.4-1.6-6.3-1.5c-2.4,0.2-5.2,2.5-8,3.2\n' +
            '      c-2.2,0.5-4.3,0.9-6.2,0.9c-2.3,0-4.4-0.4-6.3-0.5c0-1.4,1-7.5,2.1-13.1c2,0.2,5.9-0.5,10.6-4.6\n' +
            '      c2.6-2.2,6.7-5.4,9.6-8.3c2.7-2.8,11.7,0.8,14.3-2.3c1.6-2,3.7-6.9,6.8-7.7\n' +
            '      c5.2-1.4,11.7,1.8,12.9-2.1c0.4-1.5,0.5-5.7,2.6-7.6c2.9-2.6,8-3,7.3-5.5\n' +
            '      c-2.5-7.9-5.8-22.1,0.6-26.7c3.2-2.3,18.7-0.9,27.3-1.3c4.9-0.2,2.7-3.5,8.3-3.4\n' +
            '      c4.7,0.1,7,11.3,11.6,11.5c3.1,0.1,5.5-3.3,8.5-3.7c5.7-0.6,5.3,16.4,15.6,13.9\n' +
            '      c3.9-0.9,7-0.8,9.2,0.5c2.2,1.3,3.5,3.8,3.7,7.4c0.2,3.2-4.3,0.9-4.9,4.1\n' +
            '      c-0.6,3,0.4,7-0.4,10.1c-0.7,2.7-3.9,5.5-6.9,8.7c-2.3,2.5-4.1,5.4-7,8.1\n' +
            '      c-3.2,3.1-20.9,2.1-18.8,7.3c2.6,6.6-0.4,13.7-0.9,18.8c-0.5,4.9-0.5,4.9-0.2,7.5\n' +
            '      c-2.4-0.5-4.9,5.2-7,4.9c-6.1-0.9-9.9-4.4-15.4-4.3c-2.6,0.1-3.3,4.2-6.8,5\n' +
            '      c-6.7,1.6-12.5-0.1-12.2,6.1c0.2,3.9,5.9,11.6,9,18.8c4.3,10,6,19.8,2.8,22.5\n' +
            '      c-1.7,1.5-5.6-0.3-9.8-2.6c-1.6-0.9-4.1,4.8-5.7,3.9c-3.6-2.1-0.8-6.3-4.6-7\n' +
            '      c-4.2-0.8-4.2,4.9-6.4,7c-0.1,0.1-1.5-3.8-4.1-4.3c-1.6-0.3-3.2-0.3-5.1-0.5\n' +
            '      c-2.4-0.2-7.3-2.2-11.1-4.6c-3.4-2.1-5.8-4.6-9.7-7.3c-3.5-2.4-3-11.3-5.7-13.9\n' +
            '      c-2.7-2.5-7.3-3.1-9.4-6.5c-0.7-1.1-1-4.7,0.7-6.6c1-1,2.3-1.9,3.5-2.6c0.6-1.1,2.2-3.2-0.1-6.9\n' +
            '      c-0.7-1.1-4.4-5.8-0.4-9.4c2.4-2.1,7.1-0.8,11.9,0.3C154.2,259.4,155,256.6,154.3,255.7z',
    },
    {
        value: '19',
        label: 'Picardie',
        path: 'M348.5,138.5c4.1,2.8,13.4,8.3,21.2,2.4c4.8-3.6,16.3,16.5,23,13.5\n' +
            '      c2.6-1.1,4.8-3.4,6.7-6.5c1.1-1.8-4.4-6.8-3.5-9.1c0.7-1.9,3.1,0,3.8-2c1.3-3.8-3.4-6.2-2.4-10.4\n' +
            '      c1.6-6.5,15.3-1.7,17.5-7.6c3.3-8.9-4.2-13.9,0.1-17c2.5-1.8,5-1.7,6.6-5c1.7-3.6-0.3-9.8,6.9-13.6\n' +
            '      c-0.7-0.4-1.4-1-2.1-1.8c0.3,0.3-8.1,1.1-16.4-0.4c-4.3-0.8-6.9-4.3-11.9-4.4\n' +
            '      c-6.7-0.1-13.3,5.4-19.1,5c-3.7-0.3-0.3-2.7-3.6-3c-7.9-0.7-12.4,2.2-14.1-0.2\n' +
            '      c-3.3-4.7-9.6-3.7-14.4-4.4c-3.5-0.5,2.1-7.6-1.3-8c-5.3-0.6-9.5,2.8-14.2,2.4\n' +
            '      c-5.8-0.5-4.1-8.9-8.6-8.3c-7.3,1-13.8-0.8-14.3-1.4c1.7,1.8,3.2,9.6,3.2,9.6s-6.8-1.6-12.8,6.1\n' +
            '      c2.1,2.9,8.9,9,16.4,14.9c2.7,2.1-3.9,17.6-1.2,19.6\n' +
            '      c3.9,2.8-0.3,26.5,5.1,24.5C324.3,131.6,334.7,129.3,348.5,138.5z',
    },
    {
        value: '20',
        label: 'Poitou-Charentes',
        path: 'M198.8,385.2c10.9-2.2,13.4,7,14.6,8.9c1.4,2,5.7,6.5,7.9,5.5c6.6-3,12.9-6.1,18.5-9\n' +
            '      c2.3-1.2-0.1-9.3,2-10.4c2-1,3.1-2.6,4.6-3.6c2.6-1.7,5.2-2.6,7.5-3.7c1.5-0.7-0.2-4.6,1.2-7\n' +
            '      c1.7-2.9,6.6-4.4,7.8-3.8c0.1,0.1,0.1-4.7,4.2-7.7c2.3-1.7,7.7-4.3,7.6-8\n' +
            '      c-0.2-4.5-6.2-10.2-5.7-13.9c0.4-3.1,4.6-3.9,8.6-5.9c3.5-1.8,6.7-4.8,8.7-7.2\n' +
            '      c2.2-2.8-2.7-9-11-14.4c-2.4-1.5-1.2-4.5-1.5-7.7c-0.4-4-2.1-8.3-3.6-11.8c-1.6-3.7-13-2.2-18.9-5.2\n' +
            '      c-4.9-2.5-7.8-12.4-15.1-13.8c-2.4-0.5-4.9,5.2-7,4.9c-6.1-0.9-9.9-4.4-15.4-4.3\n' +
            '      c-2.6,0.1-3.3,4.2-6.8,5c-6.7,1.6-12.5-0.1-12.2,6.1c0.2,3.9,5.9,11.6,9,18.8\n' +
            '      c4.3,10,6,19.8,2.8,22.5c-1.7,1.5-5.6-0.3-9.8-2.6c-1.6-0.9-4.1,4.8-5.7,3.9c-3.6-2.1-0.8-6.3-4.6-7\n' +
            '      c-4.2-0.8-4.2,4.9-6.4,7c-0.4,0.4-3.8,9.1,2.8,16.1c2.8,3-1.5,11.3-4.6,15.1\n' +
            '      c-1.3,1.6-2.3,4.3-1.5,7.7c0.3,1.5,1.3,3.6,2.9,3.8\n' +
            '      c2.4,0.4,4.4,1.2,5.7,1.8C193,368.5,199.7,387.1,198.8,385.2z',
    },
    {
        value: '21',
        label: 'Provence-Alpes-Côte d\'azur',
        path: 'M429.3,507.9c2.2,1,1.9,2.6,14.5,2.1c2.5-0.1,4.8,0.7,6.9,2c1.2,0.7,4.2,2.9,7,3.3\n' +
            '      c3.1,0.4,6.4,0,9.6,0.4c4.7,0.5,9.6,0.9,13.8,2.9c2.3,1.1,5.1,2.9,7.1,4.3c4.3,2.8,10.8,3,14.4,3.4\n' +
            '      c3.6,0.5,4.2,3.6,7.1,5.7c1.9,1.3,3.6-0.9,6-2.6c1.5-1.1,3.7-1.9,5.1-1.7c3.8,0.6,7.2,4.2,13.9-2.9\n' +
            '      c3.3-3.5,9.1,0.5,17.9-4.3c-8.6-3.2-3-9.5-2.6-10.1c1-1.4,3.9-4.4,8-7c1.2-0.7,2.4-1.6,3.9-2.3\n' +
            '      c1-0.5,3.9-1.1,5.8-2.1c2.6-1.4,4.7-3.2,6.3-7.8c0.7-2.2,2.3-2.1,5.1-2.5c3.6-0.6,7.6-2.3,9.9-4.8\n' +
            '      c1.6-1.8,2.1-3.9,1.9-6.5c-0.1-1.3,0.6-4.9,1.2-6.9c0.6-2.1,2.1-5.4,3.7-8.1\n' +
            '      c-0.6-0.8-2.6-3.3-5.3-4.3c-3.8-1.5-4,4.9-15.6,1.4c-7.9-2.4-15.8-9.4-21.2-18.2\n' +
            '      c-3-4.9,0.6-9.5,4-14.8c8.8-13.8-13.5-15.9-15.2-25.5c-1.3,0-2.6-0.2-4-0.7\n' +
            '      c-14.1-5-18.2-1.2-19.2,0.1c-2.3,2.9,10.9,9.4,7.5,13.1c-2.2,2.3-10.1-0.8-12.4,1.8\n' +
            '      c-7.7,8.7-14.8,16.3-20.3,22c-2,2-4.8,2.4-6.7,3.1c-2.5,0.8-3.6,1.7-0.9,2.6\n' +
            '      c9,2.8,10.7,13.1,11.8,15.3c4.5,8.8-14.9,4.4-20.2,3.9c-3.6-0.3-1.3-8.2-4.3-9\n' +
            '      c-4.9-1.2-11.1,5.3-13.5,6.1c-1.9,0.7-3.6-3.6-5.5-4.3c-1.4-0.5-2.9-0.5-4.4-0.6\n' +
            '      c-1.1-0.1-1.1,11.3,9.8,22.3c-6.1,0.6-14.3,4.9-14.6,15C435.4,499.8,430.9,505.6,429.3,507.9z',
    },
    {
        value: '22',
        label: 'Rhône-Alpes',
        path: 'M475.1,312.3c1.1,4.8,4.7,6.8,6.3,9.9c1.8,3.4,6.9-8.3,8.9-5.5c2,2.8,4.5,3,6.5,4.8\n' +
            '      c3.3,3.1,10-9.5,14-7.8c0,0.5,0.2,1.4,0.5,2.1c0.8,1.6,3.8,2.8,0.9,6.1c-5.9,6.8-4.7,8.6,0.4,9.6\n' +
            '      c7.6,1.4,10.5-8.7,10.2-12.6c2-2.1,7.2-4.6,8-4.8c4.4-1.1,5.1-1.1,11.4,0.4c1.5,8.4,4.5,15,6.1,17.9\n' +
            '      c0.8,1.4,9.6,8.3,9.6,8.3s-3.3,2.7-4.9,5c-5.2,7.4-0.2,16.8,5,21.2c4.8,4.1,5.5,10.2,5.2,13.1\n' +
            '      c-0.6,7-6.9,11.2-11.5,13.5c-6.7,3.4-8.3,5.5-8.6,6.9c-1.3,0-2.6-0.2-4-0.7\n' +
            '      c-14.1-5-18.2-1.2-19.2,0.1c-2.3,2.9,10.9,9.4,7.5,13.1c-2.2,2.3-10.1-0.8-12.4,1.8\n' +
            '      c-7.7,8.7-14.8,16.3-20.3,22c-2,2-4.8,2.4-6.7,3.1c-2.5,0.8-3.6,1.7-0.9,2.6\n' +
            '      c9,2.8,10.7,13.1,11.8,15.3c4.5,8.8-14.9,4.4-20.2,3.9c-3.6-0.3-1.3-8.2-4.3-9\n' +
            '      c-4.9-1.2-11.1,5.3-13.5,6.1c-1.9,0.7-3.6-3.6-5.5-4.3c-2.3-0.9-5.2-0.2-7.5-1.1\n' +
            '      c-5.5-2.2-14.8,1.6-19.3,1.5c-2.1-0.1-5.5-0.8-6.5-4.2c-3.1-10.1-7.7-24.8-7.4-28\n' +
            '      c0.9-10,17-5.7,16-15.5c-0.6-5.5,15.7-13.4,10.9-13.6c-3.1-0.1-5.8-0.2-8-0.3\n' +
            '      c-3-0.2,3.3-9.5,0.5-9.4c-3.5,0.1-6.8,0.4-9.7,1c-10.7,2.4-12.2,0.1-10.6-9.3\n' +
            '      c1.6-10-9.2-15.2-10-17.9c-3-9.8,2.2-9.2,3.2-15.9c0.7-4.6-2.1-10.8-1.3-12.4\n' +
            '      c0.9-1.8,6.7-6.1,9.4-4.1c3.2,2.4,4,8,7.5,9.4c4.3,1.7,12.1-3.9,12.9-5.2c1.4-2.2,1.9-5.9,3.4-7.4\n' +
            '      c3.2-3.2,10.8,1,13.1,0.6c2-0.3-1.9,5.6,1.1,6c0.9,0.1,1.4-1,2-1.7c2.1-2.6,1-12.4,2.9-14.7\n' +
            '      c1.7-2,3.3-2.8,4.6-4.4c1.2-1.4,3.5,0.5,6.1,1.6C471.6,310.7,474.9,311.6,475.1,312.3z',
    },
];

type Props = {
    color: string,
    hoverColor: string,
    selectedColor: string,
    className: string,
    selected: boolean,
    label: string,
    path: string,
    value: string,
    onChange: Function,
    onHover: Function
}
type State = {
    hover: boolean
}

export class SvgElement extends React.Component<Props, State> {
    state = {
        hover: false,
    };

    onHover = (hover: boolean, region: string) => {
        this.props.onHover(region);
        this.setState({hover: hover});
    };

    render() {
        return (
            <g>
                <path
                    onClick={() => {
                        this.props.onChange(this.props.value)
                    }}
                    onMouseEnter={() => this.onHover(true, this.props.label)}
                    onMouseLeave={() => this.onHover(false, '')}
                    fill={this.props.selected ? this.props.selectedColor : this.state.hover ? this.props.hoverColor : this.props.color}
                    className={this.props.className}
                    strokeWidth={3}
                    d={this.props.path}
                />
            </g>
        )
    }
}
