// @flow

import {I18n} from 'react-redux-i18n';

export const baseApiUrl = '/api';

export const NOTIFY_CONFIRMATION = {
  message: 'Les modifications ont bien été prises en compte',
  status: 'success',
  dismissible: true,
  dismissAfter: 3000,
};

export const INFO = {
  SUCCESS: 'SUCCESS',
  WARN: 'WARN',
  ERROR: 'ERROR',
};

export const TypeStructure = {
  GAEC: 'GAEC',
  EARL: 'EARL',
  SCEA: 'SCEA',
  INDIVIDUAL: 'INDIVIDUAL',
  OTHER: 'OTHER',
};

export const structureType = [
  {
    value: 'GAEC',
    label: 'GAEC',
  },
  {
    value: 'EARL',
    label: 'EARL',
  },
  {
    value: 'SCEA',
    label: 'SCEA',
  },
  {
    value: 'INDIVIDUAL',
    label: 'Exploitation individuelle',
  },
  {
    value: 'OTHER',
    label: 'Autre',
  },
];

export const PRODUCTIONS = {
  BEETS: "Betteraves sucrières",
  BLE: "Blé tendre",
  RAPE: "Colza",
  MILK: "Lait de vache",
  CORN: "Maïs grain",
  POTATOE: "Pomme de terre",
  PORK: "Porc",

  BARLEY: 'Orge',
  BLE_HARD: 'Blé dur',
  FOIE_GRAS: 'Foie gras',
  MILK_GOAT: 'Lait de chèvre',
  EGG: 'Oeuf',
  SOY: 'Soja',
  COW_MEAT: 'Viande bovine',
  CARROT: 'Carottes',
  APPLE: 'Pomme',

  SUNFLOWER: 'Tournesol',
  MUTTON: 'Viande ovine',
  POULTRY: 'Viande de volaille',
};

export type ProductionType = $Keys<typeof PRODUCTIONS>

export const REQUIRED_FIELD = 'Champ requis';
export const INVALID_PASSWORD = I18n.t('password.errorPasswordTooShort');
