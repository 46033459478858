// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';

type Props = {
  dispatch?: Function,
  toggleForm: Function,
}
type State = {
  headerClass: boolean,
}

class Header extends React.Component<Props, State> {
  state = {
    headerClass: false,
  };

  toggleHeaderClass = (headerClass: boolean) => this.setState({headerClass});

  componentDidMount() {
    const app = document.getElementById('main-content');
    app && app.addEventListener('scroll', () => {
      let value = app.scrollTop || 0;
      this.toggleHeaderClass(value >= (this.header && this.header.offsetHeight));
    }, false);
  }

  header: HTMLElement;

  render() {
    return (
      <header className="background background--savings" ref={el => this.header = el}>
        <div className="container-title">
          <div className="logo-protelis"/>
          <div className="explanation">{I18n.t('home.header.smoothIncomesSaving')}</div>
        </div>
        <div className="subtitle">{I18n.t('home.header.savingSolution')}</div>
        <button
          className="btn btn--contact-me"
          onClick={() => this.props.toggleForm(true)}
        >{I18n.t('home.header.contactMe')}</button>
      </header>
    )
  }
}
export default Header
