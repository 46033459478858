// @flow

import React from 'react';
import {I18n} from 'react-redux-i18n';

export default function LegalMentionsModal() {
    return (
        <div className="information-modal">
            <div className="header header--CLASSIC header--minus">
                <div className="title">{I18n.t('home.modalLegal.title')}</div>
            </div>

            <div className="legal-mentions">
                <h2>Editeur du site</h2>
                <p>La Société Protélis, SAS, au capital de 1 000 000 euros, dont le siège social est à MONT-SAINT-AIGNAN
                    (76130) 16 Rue Georges Charpak, </p>
                <p>immatriculée au registre du commerce et des sociétés de ROUEN sous le numéro 849 723 580
                    représentée par Monsieur Franck ROGER, en sa qualité de Directeur Général (ci-après la " Société
                    ”). La Société propose les services suivants : Commercialisation d’une solution globale soutenant
                    une offre à destination des acteurs du monde agricole permettant un lissage du revenu des
                    exploitants agricoles dans le cadre des dispositions applicables du Code Général des impôts.</p>

                <h2>Directeur de la publication</h2>
                <p>Franck ROGER, Directeur Général.</p>

                <h2>Fournisseur de l'hébergement</h2>
                <p>Serinya Télécom - ZA du Polen, Route de Montville, 76710 Eslettes.</p>

                <h2>Droit applicable</h2>
                <p>Le site est soumis au droit français.</p>

                <h2>Droit de la propriété intellectuelle</h2>
                <p>En application du Code français de la Propriété Intellectuelle et, plus généralement, des traités et
                    accords internationaux comportant des dispositions relatives à la protection des droits d'auteur,
                    vous vous interdirez de reproduire pour un usage autre que privé, vendre, distribuer, émettre,
                    diffuser, adapter, modifier, publier, communiquer intégralement ou partiellement, sous quelque forme
                    que ce soit, les données, la présentation ou l'organisation du site ou les œuvres protégées par le
                    droit d'auteur qui figurent sur le site web "Protelis" sans autorisation écrite particulière et
                    préalable du détenteur du droit d'auteur attaché à l'œuvre, à la présentation ou à l'organisation du
                    site ou à la donnée reproduite.</p>
                <p>De manière générale, Protelis ne saurait être tenu pour responsable de tout dommage, direct ou
                    indirect, résultant de l'utilisation interdite des informations ou de tout autre élément, délivrés
                    sur le présent site Internet.</p>

                <h2>Utilisation générale du site</h2>
                <p>Protélis met tout en œuvre pour offrir aux internautes des informations, outils et services
                    disponibles et vérifiés. Il s'engage à faire ses meilleurs efforts pour sécuriser conformément à
                    l'état de l'art l'accès, la consultation et l'utilisation des informations. L’internaute déclare
                    accepter les risques et les limites de l'Internet, et reconnaître en particulier qu'il est seul
                    responsable de l'usage qu'il fait des informations. Les informations et opinions contenues sur le
                    site protelis.fr ne peuvent en aucun cas être considérées comme des conseils formels engageant la
                    responsabilité de Protélis et/ou de leurs auteurs. Les services et les données sont uniquement
                    fournis à titre d'information. Ni Protélis, ni aucun de ses partenaires fournisseurs d'informations
                    ne sauraient être tenus pour responsables des erreurs, omissions, altérations ou délais de mise à
                    jour des informations et de leurs éventuelles conséquences dans le cadre de leur utilisation. En
                    aucun cas, ils ne sauraient être tenu pour responsables des dommages directs et indirects résultant
                    de l'accès ou de l'usage du site. L’internaute a également connaissance de la nature de l'Internet,
                    notamment de ses performances techniques et des temps de réponse pour consulter, interroger ou
                    transférer les informations. Enfin, il appartient à l’internaute de prendre toutes les mesures
                    appropriées pour protéger ses propres données et/ou logiciels de la contamination par des éventuels
                    virus circulant à travers le site de Protélis et/ou des informations. Il appartient aux internautes
                    de s’équiper de manière appropriée pour accéder aux services proposés et pour les utiliser,
                    notamment en matière informatique, de télécommunications et de sécurité et de maintenir cet
                    équipement.</p>

                <h2>Accès au site</h2>
                <p>Vous êtes actuellement connecté au site Internet accessible à l'adresse protelis.fr. Nous vous
                    rappelons que nos transactions sont effectuées dans un environnement sécurisé aux normes SSL.
                    protelis.fr met tout en œuvre pour vous fournir des informations à jour et validées, mais ne saurait
                    être tenue pour responsable des erreurs, d'une absence de disponibilité des informations ou de la
                    présence d'un virus sur son site. L’internaute a également connaissance de la nature de l'Internet,
                    notamment de ses performances techniques et des temps de réponse pour consulter, interroger ou
                    transférer les informations. Enfin, il appartient à l’internaute de prendre toutes les mesures
                    appropriées pour protéger ses propres données et/ou logiciels de la contamination par des éventuels
                    virus circulant à travers le site de Protélis et/ou des informations. Il appartient aux internautes
                    de s’équiper de manière appropriée pour accéder aux services proposés et pour les utiliser,
                    notamment en matière informatique, de télécommunication et de sécurité et de maintenir cet
                    équipement.</p>

                <h2>Fonctionnalités</h2>
                <p>Ce site vous permet de souscrire un contrat Protélis pour la réaliser des prestations de Protélis de
                    façon sécurisée dans les conditions décrites au paragraphe «Sécurité et Configuration requise».</p>

                <h2>Enregistrement téléphonique</h2>
                <p>Dans le cadre de nos relations, vous pouvez être amené à nous téléphoner. Nous vous informons que ces
                    appels téléphoniques peuvent être enregistrés à des fins d’amélioration de nos produits et
                    services.</p>
                <p>Si vous ne souhaitez pas que votre appel soit enregistré, indiquez-le à votre opérateur en début de
                    communication.</p>
                <p>Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée et au Règlement
                    européen n°2016/679/UE du 27 avril 2016 (applicable depuis le 25 mai 2018), vous bénéficiez d’un
                    droit d’accès, de rectification, de portabilité et d’effacement de ses données ou encore
                    d’opposition et de limitation à leur traitement.</p>
                <p>Vous pouvez exercer vos droits à tout moment en contactant PROTELIS à l’adresse : PROTELIS, 16 rue
                    Georges Charpak – 76130 Mont Saint Aignan, France.</p>
                <p>Vous pouvez également définir des directives relatives à la conservation, à l'effacement et à la
                    communication de vos données à caractère personnel après votre décès dans les conditions prévues par
                    l'article 40-1 de la loi "Informatique et libertés" modifiée.</p>

                <h2>Réclamations</h2>
                <p>Nous sommes très attentifs à vous apporter un service de qualité, n'hésitez pas à nous faire part de
                    votre insatisfaction, si vous souhaitez formuler une réclamation, vous pouvez nous contacter par
                    courrier postal :</p>
                <p>PROTELIS, 16 rue Georges Charpak – 76130 Mont Saint Aignan, France.</p>
                <p>Protélis s'engage à accuser réception de votre réclamation dans un délai maximum de 15 jours à
                    compter de sa réception et à la traiter dans les deux mois au plus.</p>

                <h2>Loi informatique et libertés</h2>
                <p>Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée et au Règlement
                    européen n°2016/679/UE du 27 avril 2016 aucune information personnelle n'est collectée à votre insu
                    ni utilisée à des fins non prévues. Les données personnelles vous concernant, collectées sur ce
                    site, sont nécessaires à la réalisation des prestations de Protélis. Elles sont destinées aux
                    services de Protélis. Elles peuvent par ailleurs être utilisées à des fins de contrôle interne et
                    dans le cadre des dispositions légales concernant la lutte contre le blanchiment de capitaux et le
                    financement du terrorisme. Les données recueillies dans le cadre de la déclaration de sinistre sont
                    nécessaires à la gestion de vos garanties. Elles sont destinées à votre assisteur, ses mandataires,
                    prestataires et sous-traitants, aux réassureurs et organismes professionnels dans le cadre des
                    dispositions légales. Vous disposez d’un droit d'accès, de communication, de rectification et
                    d'opposition sur vos données en adressant votre demande à contact@protelis.fr ou par courrier postal
                    en écrivant à : PROTELIS, 16 RUE GEORGES CHARPAK 76130 MONT-SAINT-AIGNAN.</p>

                <h2>Configuration requise</h2>
                <p>Les échanges entre votre ordinateur et l'espace client du site protelis.fr font l'objet d'un cryptage
                    fort, afin d'éviter que des tiers puissent intercepter les données confidentielles qui circulent sur
                    le réseau internet. Ce cryptage se caractérise par une adresse débutant par "https" et par un petit
                    cadenas fermé indiquant que vous êtes bien sur une page sécurisée. Le site est optimisé pour
                    fonctionner avec les navigateurs suivants : - Internet Explorer 11.0 et versions ultérieures -
                    Firefox 1.0 et versions ultérieures Nous ne garantissons pas le fonctionnement du site avec d'autres
                    versions de navigateurs.</p>

                <h2>Pour en savoir plus sur la sécurité</h2>
                <p>Protélis vous met en garde contre l'apparition d'un nouveau type de fraude financière : le "phishing"
                    liée à l'utilisation du courrier électronique. Il s'agit de plagier partiellement ou entièrement le
                    site Internet de banques connues et à essayer d'obtenir des informations à caractère personnel ou
                    confidentiel. Cette demande est souvent motivée à des soi-disant mesures de sécurité ou de mises à
                    jour de banques de données. - Ne répondez pas à ce mail - Ne cliquez sur aucun des liens de ce mail
                    - Supprimez ce mail. Dans un souci de prévention, ne communiquez à personne vos codes secrets de
                    connexion. Sachez par ailleurs que jamais Protélis ne vous les demandera, ni par mail, ni par
                    téléphone. S'il vous arrivait de recevoir un message par Internet vous demandant de le faire,
                    transmettez-le-nous immédiatement.</p>

                <h2>Avertissement</h2>
                <p>Vous déclarez connaître les caractéristiques et les limites d’Internet. Protélis ne pourra pas être
                    tenu responsable du fonctionnement du réseau Internet et notamment des délais de transmission, de la
                    fiabilité des transmissions de données, des temps d’accès ou éventuellement des restrictions d’accès
                    sur des réseaux et/ou serveurs spécifiques connectés au réseau Internet. Protélis fournit ses
                    meilleurs efforts pour maintenir l’accès au site. Cependant, il peut être amené à interrompre
                    l’accès au site temporairement ou définitivement, notamment pour des raisons juridiques, techniques
                    ou de maintenance. Aucune indemnité ne peut être octroyée au titre d’une indisponibilité du site,
                    qu’elle soit temporaire ou définitive. La responsabilité de Protélis, limitée aux dommages directs,
                    ne pourra être engagée qu’en cas d’erreur manifeste ou de faute lourde. Protélis met tout en oeuvre
                    pour offrir aux internautes des informations, outils et services disponibles et vérifiés. Il
                    s'engage à faire ses meilleurs efforts pour sécuriser conformément à l'état de l'art l'accès, la
                    consultation et l'utilisation des informations. L’internaute déclare accepter les risques et les
                    limites de l'Internet, et reconnaître en particulier qu'il est seul responsable de l'usage qu'il
                    fait des informations. Les informations et opinions contenues sur le site Protélis.fr ne peuvent en
                    aucun cas être considérées comme des conseils formels engageant la responsabilité de Protélis et/ou
                    de leurs auteurs. Les services et les données sont uniquement fournis à titre d'information. Ni
                    Protélis, ni aucun de ses partenaires fournisseurs d'informations ne sauraient être tenus pour
                    responsables des erreurs, omissions, altérations ou délais de mise à jour des informations et de
                    leurs éventuelles conséquences dans le cadre de leur utilisation. En aucun cas, ils ne sauraient
                    être tenu pour responsables des dommages directs et indirects résultant de l'accès ou de l'usage du
                    site. L’internaute a également connaissance de la nature de l'Internet, notamment de ses
                    performances techniques et des temps de réponse pour consulter, interroger ou transférer les
                    informations. Enfin, il appartient à l’internaute de prendre toutes les mesures appropriées pour
                    protéger ses propres données et/ou logiciels de la contamination par des éventuels virus circulant à
                    travers le site de Protélis et/ou des informations. Il appartient aux internautes de s’équiper de
                    manière appropriée pour accéder aux services proposés et pour les utiliser, notamment en matière
                    informatique, de télécommunications et de sécurité et de maintenir cet équipement.</p>
            </div>
        </div>
    )
}
