// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';

type Props = {
  toggleForm: Function
}

export default function Part3({toggleForm}: Props) {
  return (
    <>
      <div className="title-cat">{I18n.t('home.part3.whatProcedure')}</div>
      <div className="timeline">
        <div className="timeline-item">
          <div className="wrapped-icon ">
            <i className="icon icon--people-white" />
          </div>
          <div className="desc">
            <div className="title">
              {I18n.t('home.part3.relation')}
            </div>
            <div className="detail">
              <div
                onClick={() => toggleForm(true)}
                className="underline"
              >
                {I18n.t('home.part3.bold.contactCoop')}
              </div>
              {I18n.t('home.part3.aboutProtelis')}
            </div>
          </div>
        </div>
        <div className="timeline-item">
          <div className="wrapped-icon ">
            <i className="icon icon--search-white" />
          </div>
          <div className="desc">
            <div className="title">
              {I18n.t('home.part3.fileStudies')}
            </div>
            <div className="detail">
              <div className="bold">{I18n.t('home.part3.bold.studySituation')}</div>
              {I18n.t('home.part3.withMyCounselor')}
            </div>
          </div>
        </div>
        <div className="timeline-item">
          <div className="wrapped-icon ">
            <i className="icon icon--tablet-white" />
          </div>
          <div className="desc">
            <div className="title">
              {I18n.t('home.part3.inscription')}
            </div>
            <div className="detail">
              <div className="bold">{I18n.t('home.part3.bold.subscribe')}</div>
              {I18n.t('home.part3.protelisService')}
            </div>
          </div>
        </div>
        <div className="timeline-item">
          <div className="wrapped-icon ">
            <i className="icon icon--economy-white" />
          </div>
          <div className="desc">
            <div className="title">
              {I18n.t('home.part3.saving')}
            </div>
            <div className="detail">
              {I18n.t('home.part3.iCan')}
              <div className="bold">{I18n.t('home.part3.bold.save')}</div>
              {I18n.t('home.part3.choices')}
            </div>
          </div>
        </div>
      </div>
      <button
        className="btn btn--yellow contact"
        onClick={() => toggleForm(true)}
      >
        {I18n.t('home.part3.contactMe')}
      </button>
    </>
  )
}
