// @flow

import React from 'react';

type Props = {
  list: Object[],
  label?: string,
  placeholder?: string,
  input: {
    value: ?string,
    onChange: Function
  },
  disabled?: boolean,
  meta: {
    error: string,
    touched: boolean,
  }
}

class SelectPro extends React.Component<Props> {
  componentDidMount() {
    if (this.props.list.filter(elem => this.props.input.value === elem.value).length === 0) {
      this.props.input.onChange('');
    }
  }

  hasError = () => this.props.meta.error && this.props.meta.touched;

  render() {
    return (
      <>
        <div className="container-input-pro">
          {
            this.props.label &&
            <div className="label">{this.props.label}</div>
          }
          <select
            className={`input-pro ${this.hasError() ? 'input-pro--error' : ''}`}
            {...this.props.input}
            onChange={(e) => {
              if (this.props.onChange) this.props.onChange(e.target.value);
              this.props.input.onChange(e.target.value);
              }
            }
            disabled={this.props.disabled}>
            <option value="" selected disabled>{this.props.placeholder || ''}</option>
            {
              this.props.list.map(opt => (
                <option value={opt.value} key={opt.label}>{opt.label}</option>
              ))
            }
          </select>
          {
            this.hasError() &&
            <div className="error-form error-form--field">{this.props.meta.error}</div>
          }
        </div>
      </>
    );
  }
}

export default SelectPro;
