// @flow

import { RestClient } from '../network';
import type {ExtranetProspect} from "../../types/ExtranetTypes";

const extranetApi = {
  checkToken: (token: string) => new RestClient(`/extranet/checkToken/${token}`, 'GET').execute(),
  insertProspect: (prospect: ExtranetProspect) => new RestClient(`/extranet/insertProspect`, 'POST').jsonBody(prospect).execute()
};

export default extranetApi;