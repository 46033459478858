// @flow
import React from 'react';
import NotificationsSystem from 'reapop';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';

import {
  CONTACT_FORM, EMAIL_CHANGE_CONFIRM,
  EXTRANET,
  HOME_PAGE_ROUTE,
  PASSWORD,
  PRECAUTIONARY_SAVINGS,
  VIRTUAL_ADVISOR_CONNECT,
} from './routes';
import ConfirmationModal from './components/modaleNotification/ConfirmationModal';
import theme from './components/modaleNotification/theme/index';
import Home from './pages/home/Home';
import PasswordRoutes from './pages/password/PasswordRoutes';
import ExtranetContractRequest from "./pages/extranet/ExtranetContractRequest";
import VirtualAdvisorConnect from "./pages/virtualAdvisor/VirtualAdvisorConnect";
import PrecautionarySavings from './pages/home/PrecautionarySavings';
import ContactForm from './pages/home/ContactForm';
import EmailManagement from "./pages/email/EmailManagement";

type Props = {
  isAsideNavOpen: boolean,
};

function App({ isAsideNavOpen }: Props) {
  return (
    /* eslint-disable react/jsx-filename-extension */
    <div id="app">
      <NotificationsSystem theme={theme} />
      <ConfirmationModal />
      <div className="container">
        <div className="content-container">
          <main id="main-content">
            <Switch>
              <Route exact path={HOME_PAGE_ROUTE} component={Home} />
              <Route exact path={PRECAUTIONARY_SAVINGS} component={PrecautionarySavings} />
              <Route exact path={CONTACT_FORM} component={ContactForm} />
              <Route path={PASSWORD} component={PasswordRoutes} />
              <Route path={EXTRANET} component={ExtranetContractRequest} />
              <Route path={VIRTUAL_ADVISOR_CONNECT} component={VirtualAdvisorConnect} />
              <Route path={EMAIL_CHANGE_CONFIRM} component={EmailManagement} />

              <Route path="*" render={() => <Redirect to={HOME_PAGE_ROUTE} />} />
            </Switch>
          </main>
        </div>
      </div>
    </div>
  );
}

export default withRouter(App);
