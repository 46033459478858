// @flow

import React from 'react';
import StepContainer from '../../../components/StepContainer';
import ProductionForm from './form/ProductionForm';
import { setFormValidated } from '../../../state/home/homeService';
import { connect } from 'react-redux';
import homeApi from '../../../network/api/HomeApi';
import { INFO } from '../../../const';
import type { Resources } from '../../../types/HomeTypes';

type Props = {
  dispatch: Function,
  resources: Resources,
}

type State = {
  stepForm: 1 | 2 | 3 | 4,
  values: {
    productions: string[],
    cooperative: string[]
  }
}

class ModalFormContent extends React.Component<Props, State> {
  state = {
    stepForm: 1,
    values: {
      productions: [],
      cooperative: [],
    }
  };

  handleSubmit = values => {
    homeApi.createProspect({...values, cooperative: values.cooperative[0]})
      .then(() => {
        this.props.dispatch(setFormValidated({
          status: INFO.SUCCESS,
          protelis: values.cooperative[0] === '0',
          coop: this.props.resources.cooperatives.find(coop => coop.value === values.cooperative[0]).label,
          mail: values.mail}))
      })
      .catch(e => {
        this.props.dispatch(setFormValidated({
          status: INFO.WARN,
          protelis: null,
          coop: '',
          mail: ''}))
      })
  }

  render() {
    return (
      <div>
        <StepContainer currentStep={this.state.stepForm} values={this.state.values}
        />
        <ProductionForm
          currentStep={this.state.stepForm}
          setStepForm={(stepForm) => this.setState({stepForm})}
          saveValues={(values) => {
            this.setState({values})}
          }
          values={this.state.values}
          handleSubmit={this.handleSubmit}
        />
      </div>
    )
  }
}

export default connect(state => ({
  resources: state.home.resources,
}))(ModalFormContent);